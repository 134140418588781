import React, { useContext, useEffect } from "react";
import { Card, Col, Empty,  Row } from "antd";
import Play from "../../../assets/images/play.svg";
import Thumbnail from "../../../assets/images/chatLogo.svg";
import { useLocation, Link } from "react-router-dom";
import { VisionShootingContext, VisionShootingContextProvider } from "../../../context/user-context/vision-shooting-context";
import { getDateWithoutDay } from "../../../util/config";
import ShootingDetailModal from "./shooting-detail-modal";
import "./vision-shooting-sessions.scss"
import { UserContext } from "../../../context/user-context";
const style = {
  padding: "5px 0",
};
const ShootingDetailPage = () => {
  const {selectedNotification} = useContext(UserContext)
  const location = useLocation();
  const { sessionsData , setSelectedShooting ,params, setShootingDetailModal , getShootingDetail , shootingDetailModal , setShootingDetailView , setLeaderboardProfile, getVisionLeaderBoard} = useContext(VisionShootingContext);
  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if (crumb !== "home" && crumb !== "groups") {
        let newCrumb = crumb.replace("-", " ");
        return (
          <div className="crumb" key={newCrumb}>
            <Link
              style={{ cursor: "text" }}
            >
              {decodeURI(newCrumb)}
            </Link>
          </div>
        );
      }
    });
    useEffect(()=>{
      if(params.tab === "shooting_sessions"){
        setShootingDetailModal(true)
      }
    },[selectedNotification])
  return (
    <div className="user-shooting-detail-main">
      <div className="heading-row">
        {/* <FaArrowLeftLong
          className="left-arrow"
          style={{ margin: "0 0 8px 0", color: color, stroke: color }}
            onClick={() => {setShootingDetailView(false);setLeaderboardProfile({});getVisionLeaderBoard()}}
        /> */}
        <h1>All Vision Shooting Sessions</h1>
      </div>
      <div className="breadcrumbs">
        <div style={{ display: "flex" }}>
          {crumbs}
          <div className="crumb" style={{ cursor: "text" }}>
            <a style={{ cursor: "text" }}>
              All Vision Shooting Sessions
            </a>
          </div>
        </div>
      </div>
      <hr />
      <Row
        style={{ display: "flex" }}
        className="groups-row submission-video"
        gutter={[34, 34]}
      >
        {sessionsData?.length > 0 ? (
          sessionsData.map((item, index) => {
            return (
              <Col
                className="gutter-row"
                span={8}
                style={{ paddingBottom: 16 }}
                key={index}
              >
                <div style={style} className="div-1">
                  <Card
                    hoverable
                    className="video-card"
                    style={{
                      cursor:
                        item.media_url !== null ? "pointer" : "default",
                    }}
                    onClick={
                      item.media_url === null
                        ? null
                        : () => {
                            setSelectedShooting(item);
                            setShootingDetailModal(true);
                            getShootingDetail(item.id);
                          }
                    }
                    cover={
                      <>
                        <div id="video-container">
                          {
                            item?.media_thumbnail ? (
                              <div
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                }}
                              >
                                <img
                                  src={item.media_thumbnail}
                                  style={{ width: "100%", height: "164px" }}
                                />
                                <img
                                  src={Play}
                                  style={{ width: 35, position: "absolute" }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={Thumbnail}
                                  style={{ width: "40%", marginTop: "12%" }}
                                ></img>
                              </div>
                            )
                          }
                        </div>
                      </>
                    }
                  >
                    <div
                      className="date"
                    >
                      Submitted:{" "}
                      <span style={{ fontWeight: "bold", color: "#181c32" }}>
                        {getDateWithoutDay(item.created_at)}
                      </span>
                    </div>
                    <div
                      className="date"
                    >
                      Shots:{" "}
                      <span style={{ fontWeight: "bold", color: "#181c32" }}>
                        {item.total_make}/{item.total_attempts}
                      </span>
                    </div>
                  </Card>
                </div>
              </Col>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: "70px auto" }}
          />
        )}
      </Row>
      {shootingDetailModal === true &&
      <ShootingDetailModal />
}
    </div>
  );
};


const ShootingDetail = () => {
  return(
    <VisionShootingContextProvider>
      <ShootingDetailPage />
    </VisionShootingContextProvider>
  )
}


export default ShootingDetail;
