import { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Checkbox,
  Row,
  Col,
  Spin,
  Skeleton,
  message,
  Button,
  Radio,
} from "antd";
import Avatar from "react-avatar";
import { deleteItem } from "../../../util/coach-api-calls";
import { UserContext } from "../../../context/user-context";
import { MessagesContext } from "../../../coach-context/group-context/messages-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
let filteredMembers = [];
let filteredOwners = [];
const EditSubgroupModal = () => {
  const { selectedSport, darkButtonBackground } = useContext(UserContext);
  const {
    clickedSubgroup,
    members,
    updateSubGroup,
    loading,
    imageLoaded,
    setImageLoaded,
    setSubGroupMembers,
    subGroupMembers,
    setEditSubGroup,
    listingMembers,
    setEditSubGroupName,
    editSubGroup,
    setClickedSubGroup,
    profileType,
    setProfileType,
    owners,
    subGroupOwners,
    setSubGroupOwners,
  } = useContext(MessagesContext);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [form] = Form.useForm();
  const handleOk = () => {
    setEditSubGroup(false);
    updateSubGroup();
    form.resetFields();
  };
  const handleCancel = () => {
    setProfileType("members");
    setClickedSubGroup({});
    setEditSubGroup(false);
  };
  const deleteSubgroup = () => {
    setEditSubGroup(false);
    deleteItem(`messages/group/1443/subgroup/${clickedSubgroup.id}`)
      .then((res) => {
        message.success({
          content: "Subgroup deleted successfully.",
        });
        listingMembers();
        setEditSubGroup(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClick = (e, item) => {
    const { id, checked } = e.target;
    setSubGroupMembers([...subGroupMembers, item]);
    if (!checked) {
      setSubGroupMembers(subGroupMembers.filter((prev) => prev !== item));
    }
  };
  const handleClickOwners = (e, item) => {
    const { id, checked } = e.target;
    setSubGroupOwners([...subGroupOwners, item]);
    if (!checked) {
      setSubGroupOwners(subGroupOwners.filter((prev) => prev !== item));
    }
  };
  useEffect(() => {
    if (subGroupMembers?.length < 2 && subGroupOwners?.length === 0) {
      setButtonDisabled(true);
    }
    if (subGroupOwners?.length < 2 && subGroupMembers?.length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [subGroupMembers, subGroupOwners]);
  useEffect(() => {
    form.setFieldsValue({
      subgroup_name: clickedSubgroup.name,
    });
  }, []);
  const modalConfirm = () => {
    Modal.confirm({
      title: "Delete Subgroup",
      content: "Are you sure you want to delete this subgroup?",
      icon: null,
      wrapClassName:
        selectedSport === 1 ? "orange-delete-modal" : "blue-delete-modal",
      rootClassName:
        selectedSport === 1 ? "orange-delete-modal" : "blue-delete-modal",
      className:
        selectedSport === 1 ? "orange-delete-modal" : "blue-delete-modal",
      cancelText: "No",
      okText: "Yes",

      onOk() {
        deleteSubgroup();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleModeChange = (e) => {
    setProfileType(e.target.value);
  };
  useEffect(() => {
    if (
      clickedSubgroup?.members?.length > 0 &&
      (members?.results?.length > 0 || owners?.length > 0)
    ) {
      filteredMembers = members?.results?.filter(
        (item2) =>
          !clickedSubgroup?.members.some((item1) => item1.id === item2.id)
      );
      filteredOwners = owners?.filter(
        (item2) =>
          !clickedSubgroup?.owners.some((item1) => item1.id === item2.id)
      );
    }
  }, [clickedSubgroup, members, owners]);
  return (
    <Modal
      className="edit-subgroup-modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      open={editSubGroup}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Finish Editing"}
      cancelText={"Cancel"}
      width={"624px"}
      okButtonProps={{ disabled: buttonDisabled }}
    >
      <h1>Edit Subgroup</h1>
      <p>Edit the subgroup details</p>
      <Form form={form}>
        <Form.Item
          label="Subgroup Name"
          name={"subgroup_name"}
          {...formItemLayout}
        >
          <Input
            placeholder="Please enter subgroup name."
            onChange={(e) => setEditSubGroupName(e.target.value)}
          />
        </Form.Item>
      </Form>
      <div className="members-div">
        <Radio.Group
          onChange={handleModeChange}
          value={profileType}
          style={{
            marginBottom: 30,
            justifyContent: "center",
            display: "flex",
          }}
          className={
            selectedSport === 1
              ? "category-radio-tabs-orange"
              : "category-radio-tabs-blue"
          }
        >
          <Radio.Button value="members">Members</Radio.Button>
          <Radio.Button value="owners">Owners</Radio.Button>
        </Radio.Group>
        <div style={{ display: "flex", width: "100%" }}>
          <h2>Members ({subGroupMembers.length + subGroupOwners?.length}) </h2>
        </div>
        {loading && loading == true ? (
          <Spin
            className={
              "loader " +
              (selectedSport === 1
                ? "orange-theme-loader"
                : "blue-theme-loader")
            }
            size="large"
            style={{
              position: "absolute",
              left: "32%",
              marginTop: -16,
            }}
          />
        ) : (
          <Row className="member-row">
            <Col span={24} style={{ height: 300, overflowY: "auto" }}>
              {profileType === "members" ? (
                <>
                  {clickedSubgroup.members.map(function (item, index) {
                    return (
                      profileType === "members" && (
                        <Row className="owner-row" key={index}>
                          <Col span={24} style={{ display: "flex" }}>
                            <Checkbox
                              key={index}
                              type="checkbox"
                              name={item.name}
                              id={item.id}
                              className="custom-checkbox"
                              onChange={(e) => handleClick(e, item)}
                              style={{ marginRight: 26 }}
                              checked={subGroupMembers.some(
                                (member) =>
                                  member.id === item.id &&
                                  member.username === item.username
                              )}
                              defaultChecked={subGroupMembers.some(
                                (member) =>
                                  member.id === item.id &&
                                  member.username === item.username
                              )}
                            />
                            {item.avatar !== null && imageLoaded === "" && (
                              <Skeleton.Image
                                active={true}
                                className="user-skeleton"
                              />
                            )}
                            {!item.avatar ? (
                              <Avatar
                                name={item.first_name + " " + item.last_name}
                                color={
                                  selectedSport === 1 ? "#F15A29" : "#4EA7F8"
                                }
                                className="user-avatar"
                              />
                            ) : (
                              <img
                                src={item.avatar}
                                style={
                                  imageLoaded === ""
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                                onLoad={() => setImageLoaded("loaded")}
                              ></img>
                            )}
                            <div className="name-div">
                              <h3 className="user-name">
                                {item.first_name + " " + item.last_name}
                              </h3>
                              <h4 className="user-handle">@{item.username}</h4>
                            </div>
                          </Col>
                          <hr />
                        </Row>
                      )
                    );
                  })}
                  {filteredMembers.length > 0 &&
                    filteredMembers.map(function (item, index) {
                      return (
                        <Row className="owner-row" key={index}>
                          <Col span={24} style={{ display: "flex" }}>
                            <Checkbox
                              type="checkbox"
                              className="custom-checkbox"
                              onChange={(e) => handleClick(e, item)}
                              style={{ marginRight: 26 }}
                              checked={subGroupMembers.some(
                                (member) =>
                                  member.id === item.id &&
                                  member.username === item.username
                              )}
                              defaultChecked={subGroupMembers.some(
                                (member) =>
                                  member.id === item.id &&
                                  member.username === item.username
                              )}
                            />
                            {item.avatar !== null && imageLoaded === "" && (
                              <Skeleton.Image
                                active={true}
                                className="user-skeleton"
                              />
                            )}
                            {!item.avatar ? (
                              <Avatar
                                name={item.first_name + " " + item.last_name}
                                color={
                                  selectedSport === 1 ? "#F15A29" : "#4EA7F8"
                                }
                                className="user-avatar"
                              />
                            ) : (
                              <img
                                src={item.avatar}
                                style={
                                  imageLoaded === ""
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                                onLoad={() => setImageLoaded("loaded")}
                              ></img>
                            )}
                            <div className="name-div">
                              <h3 className="user-name">
                                {item.first_name + " " + item.last_name}
                              </h3>
                              <h4 className="user-handle">@{item.username}</h4>
                            </div>
                          </Col>
                          <hr />
                        </Row>
                      );
                    })}{" "}
                </>
              ) : (
                <>
                  {clickedSubgroup.owners.map(function (item, index) {
                    return (
                      <Row className="owner-row" key={index}>
                        <Col span={24} style={{ display: "flex" }}>
                          <Checkbox
                            key={index}
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            className="custom-checkbox"
                            onChange={(e) => handleClickOwners(e, item)}
                            style={{ marginRight: 26 }}
                            // defaultChecked={true}
                            defaultChecked={subGroupOwners.some(
                              (member) =>
                                member.id === item.id &&
                                member.username === item.username
                            )}
                            checked={subGroupOwners.some(
                              (member) =>
                                member.id === item.id &&
                                member.username === item.username
                            )}
                          />
                          {item.avatar !== null && imageLoaded === "" && (
                            <Skeleton.Image
                              active={true}
                              className="user-skeleton"
                            />
                          )}
                          {!item.avatar ? (
                            <Avatar
                              name={item.first_name + " " + item.last_name}
                              color={
                                selectedSport === 1 ? "#F15A29" : "#4EA7F8"
                              }
                              className="user-avatar"
                            />
                          ) : (
                            <img
                              src={item.avatar}
                              style={
                                imageLoaded === ""
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              onLoad={() => setImageLoaded("loaded")}
                            ></img>
                          )}
                          <div className="name-div">
                            <h3 className="user-name">
                              {item.first_name + " " + item.last_name}
                            </h3>
                            <h4 className="user-handle">@{item.username}</h4>
                          </div>
                        </Col>
                        <hr />
                      </Row>
                    );
                  })}
                  {filteredOwners.length > 0 &&
                    filteredOwners.map(function (item, index) {
                      return (
                        <Row className="owner-row" key={index}>
                          <Col span={24} style={{ display: "flex" }}>
                            <Checkbox
                              type="checkbox"
                              className="custom-checkbox"
                              onChange={(e) => handleClickOwners(e, item)}
                              style={{ marginRight: 26 }}
                              checked={subGroupOwners.some(
                                (member) =>
                                  member.id === item.id &&
                                  member.username === item.username
                              )}
                              defaultChecked={subGroupOwners.some(
                                (member) =>
                                  member.id === item.id &&
                                  member.username === item.username
                              )}
                            />
                            {item.avatar !== null && imageLoaded === "" && (
                              <Skeleton.Image
                                active={true}
                                className="user-skeleton"
                              />
                            )}
                            {!item.avatar ? (
                              <Avatar
                                name={item.first_name + " " + item.last_name}
                                color={
                                  selectedSport === 1 ? "#F15A29" : "#4EA7F8"
                                }
                                className="user-avatar"
                              />
                            ) : (
                              <img
                                src={item.avatar}
                                style={
                                  imageLoaded === ""
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                                onLoad={() => setImageLoaded("loaded")}
                              ></img>
                            )}
                            <div className="name-div">
                              <h3 className="user-name">
                                {item.first_name + " " + item.last_name}
                              </h3>
                              <h4 className="user-handle">@{item.username}</h4>
                            </div>
                          </Col>
                          <hr />
                        </Row>
                      );
                    })}
                </>
              )}
            </Col>
          </Row>
        )}
      </div>
      <Button
        type="primary"
        onClick={modalConfirm}
        style={{
          position: "absolute",
          marginTop: 12,
          background: darkButtonBackground,
        }}
      >
        Delete Subgroup
      </Button>
    </Modal>
  );
};
export default EditSubgroupModal;
