import { useContext, useEffect, useRef, useState } from "react";
import {
  Modal,
  Input,
  Radio,
  Form,
  Upload,
  Button,
  Card,
  Image,
  Progress,
  Checkbox,
  Select,
} from "antd";
import UploadFile from "../../../assets/images/upload.svg";
import * as tus from "tus-js-client";
import axios from "axios";
import { CloseOutlined } from "@ant-design/icons";
import QuestionMark from "../../../assets/images/question_mark.svg";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import DownArrow from "../../../assets/images/down-arrow.svg";
import { formPostDataTraining, getDataTraining } from "../../../util/api-calls";
import {UserContext} from "../../../context/user-context"
import ReactPlayer from "react-player";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const { Dragger } = Upload;
const { TextArea } = Input;

const NewTask = ({
  newTaskModal,
  setNewTaskModal,
  newTaskArray,
  setNewTaskArray,
  generalDetail
}) => {
  const {darkButtonBackground , selectedSport , color , lightButtonBackground , allTags , getAllTags} = useContext(UserContext)
  const wrapperRef = useRef();
  const [value, setValue] = useState(1);
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  let groupName = window.location.pathname.slice(
    window.location.pathname.lastIndexOf("/") + 1,
    window.location.pathname.length
  );
  const [videoUrl, setVideoUrl] = useState();
  const [percentage, setPercentage] = useState(0);
  const [videoPreview, setVideoPreview] = useState(false);
  const [detailImagePreview, setDetailImgaePreview] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [imagePreview, setImagePreview] = useState(false);
  const [detailMediaError, setDetailMediaError] = useState(false);
  const [uploadingStart, setUploadingStart] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [taskType, setTaskType] = useState(false);
  const [videoRequired, setVideoRequired] = useState("yes");
  const [timeLimit, setTimeLimit] = useState(false);
  const [timeLimitSeconds, setTimeLimitSeconds] = useState("");
  const [taskStatus, setTaskStatus] = useState("public");
  const [level, setLevel] = useState("beginner");
  const [uploadError, setUploadError] = useState(false);
  const [tags, setTags] = useState([]);
  const [detailImgSrc, setDetailImgSrc] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [mediaType, setMediaType] = useState("upload");
  const [detailVideoId, setDetailVideoId] = useState("");

  useEffect(()=>{
    getAllTags(3)
  },[])
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const createNewTask = (values) => {
    let instructionError =
      values.instructions === undefined || values.instructions === ""
        ? true
        : false;
    if (
      mediaType === "upload" &&
      instructionError === true &&
      detailVideoId === "" &&
      !detailImageUrl
    ) {
      setUploadError(true);
    } else {
      setUploadError(false);
      let formData = new FormData();
      formData.append("title", values.task_title);
      formData.append("order", newTaskArray.length + 1);
      if (detailVideoId) {
        formData.append("media_uri", detailVideoId);
      }
      if (detailImageUrl) {
        formData.append("file", detailImageUrl);
      }
      if (values.media_url) {
        formData.append("media_url", values.media_url);
      }
      formData.append(
        "media_type",
        detailVideoId
          ? "vimeo"
          : values.media_url
          ? "embedded"
          : detailImageUrl
          ? "image"
          : ""
      );
      formData.append(
        "media_required",
        values.video_required === "yes" ? true : false
      );
      formData.append("instruction", values.instructions ? values.instructions : "");
      formData.append(
        "is_public",
        // values.task_status === "public" ? true :
         "false"
      );
      // formData.append('best_score', '100');
      formData.append("skill_level", values.level);
      formData.append(
        "is_time_limit",
        values.time_limit === true ? true : false
      );
      if (timeLimitSeconds !== "") {
        formData.append("time_limit_seconds", timeLimitSeconds);
      }
      formData.append(
        "score_based_test",
        values.task_type === true ? true : false
      );
      formData.append("tags", tags.toString().toLowerCase());
      formData.append("group_id", params.id);
      formData.append("group_name", decodeURI(groupName));
      formData.append("num_completion", 1);
      formData.append("legacy_point", 0);
      formData.append("allow_more_reps", false);
      formData.append("lock", false);
      formData.append("best_score", 100);
      formData.append("is_cw",generalDetail.is_curriculum_group)
      formPostDataTraining(`task`, formData)
        .then((res) => {
          if(res && res.status === 201){
          setNewTaskArray((prevTask) => [...prevTask, res.data]);
          setNewTaskModal(false);
          form.resetFields();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeTags = (checkedValues) => {
    setTags(checkedValues);
  };
  const tagOptions = [];
  for (let i = 0; i < allTags.length; i++) {
    tagOptions.push({
      value: allTags[i].tag.toLowerCase(),
      label: allTags[i].tag,
    });
  }
  async function handleBeforeUpload(file) {
    setDetailVideoId("");
    setDetailImageUrl();
    if (file.type === "image/png" || file.type === "image/jpeg") {
      setVideoUrl();
      setDetailImageUrl(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setDetailImgSrc(reader.result);
      };
      setVideoPreview(true);
      setDetailMediaError(false);
      reader.readAsDataURL(file);
      return false;
    } else {
      setDetailImgSrc();
      setUploadingStart(true);
      setVideoUrl(file);
      setVideoPreview(true);
      setDetailMediaError(false);
      // setDetailVideoId("888210996");
      await uploadVideo(file, handleSuccessfullBytes, uploadCompleteDone);
    }
  }
  const uploadVideo = async (
    file,
    handleSuccessfullBytes,
    uploadCompleteDone
  ) => {
    const uploadLink = await createVideo(file.size);
    let bytes = 0;
    const upload = new tus.Upload(file, {
      uploadUrl: uploadLink?.upload?.upload_link,
      retryDelays: [1000, 3000, 5000],
      uploadSize: file.size,
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onShouldRetry: function (err, retryAttempt, options) {
        var status = err.originalResponse
          ? err.originalResponse.getStatus()
          : 0;
        // Do not retry if the status is a 403.
        if (status === 403) {
          return false;
        }

        // For any other status code, we retry.
        return true;
      },
      onChunkComplete: (status) => {},
      onError: (error) => {},
      onProgress: (uploadedBytes, totalBytes) => {
        bytes = uploadedBytes;
        handleSuccessfullBytes(uploadedBytes, totalBytes);
      },
      onSuccess: () => {
        uploadCompleteDone(true, uploadLink?.uri, uploadLink.link);
      },
    });

    upload.start();
  };
  const handleSuccessfullBytes = (bytes, totalBytes) => {
    const bytesInPercentage = Math.round((bytes / totalBytes) * 100);
    setPercentage(bytesInPercentage);
  };
  const uploadCompleteDone = (isCompleted, detailVideoId, url) => {
    setVideoSrc(url);
    setVideoPreview(true);
    setUploadComplete(isCompleted);
    const video_id = detailVideoId.split("/");
    setDetailVideoId(video_id[2]);
    setUploadingStart(false);
  };
  const createVideo = async (fileSize) => {
    const params = {
      upload: {
        approach: "tus",
        size: fileSize,
      },
      name: "My Video",
    };
    const response = await axios.post(
      "https://api.vimeo.com/me/videos",
      params,
      {
        headers: {
          Accept: "application/vnd.vimeo.*+json;version=3.4",
          "Content-Type": "application/json",
          Authorization: `Bearer be82d2e853d829ed867903ec070a64f7`,
        },
      }
    );

    return response?.data;
  };
  const handleOk = () => {
    setNewTaskModal(false);
  };
  const handleCancel = () => {
    setNewTaskModal(false);
  };
  const onChangeMediaType = (e) => {
    form.setFieldValue("media_url", "");
    resetDragger();
    setMediaType(e.target.value);
  };
  const resetDragger = () => {
    setVideoUrl();
    setDetailVideoId("");
    setDetailImageUrl();
    setVideoPreview(false);
    setDetailImgSrc();
  };

  const resetForm = () => {
    setTags((tags) => []);
    setTaskType(false);
    setVideoRequired("yes");
    setTimeLimit(false);
    setTaskStatus("public");
    setLevel("beginner");
    setMediaType("upload");
    setDetailImgSrc();
    setVideoUrl();
    setVideoPreview(false);
    setDetailImgaePreview(false);
    setDetailVideoId("");
    setDetailImageUrl();
  };
  useEffect(() => {
    form.setFieldsValue({
      task_type: taskType,
      video_required: videoRequired,
      time_limit: timeLimit,
      task_status: taskStatus,
      level: level,
    });
    resetForm();
  }, [newTaskModal]);
  return (
    <Modal
      className="task-modal"
      wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
      open={newTaskModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"699px"}
      footer={false}
    >
      <h1>Add New Task</h1>
      <p>Create a new task</p>
      <Form
        form={form}
        name="new_task"
        className="new-task-form"
        onFinish={createNewTask}
      >
        <Form.Item
          {...formItemLayout}
          name="task_title"
          className="task-title"
          label="Task Title"
          rules={[
            {
              required: true,
              message: "Please enter task title",
            },
          ]}
        >
          <Input className="task-name" />
        </Form.Item>{" "}
        <Form.Item {...formItemLayout} label="Add Media" className="media-div">
          <p className="instructions">
            Upload or link a photo or a video for instructions.
          </p>
          <Radio.Group
            onChange={onChangeMediaType}
            value={mediaType}
            className="custom-radio"
          >
            <Radio value={"upload"}>Upload File</Radio>
            <Radio value={"url"}>Add from URL</Radio>
          </Radio.Group>
          {mediaType === "upload" ? (
            <div
              style={{ marginTop: "32px" }}
              className={
                videoPreview || detailImagePreview ? "uploader-div" : ""
              }
            >
              <Dragger
                type="file"
                customRequest={dummyRequest}
                beforeUpload={handleBeforeUpload}
                showUploadList={false}
                className={selectedSport === 1  ? "orange-dragger" : "blue-dragger" }
              >
                <p className="ant-upload-drag-icon">
                  <img src={UploadFile} alt="" className={selectedSport === 1  ? "orange-theme-icon" : "blue-theme-icon" }/>
                </p>
                <div style={{ textAlign: "left", padding: "12px 0" }}>
                  {" "}
                  <p className="ant-upload-text">
                    Drag and Drop or
                    <span>Browse</span>
                  </p>
                  <p className="ant-upload-hint">
                    Upload photo or video (16:9 ratio)
                  </p>
                </div>
              </Dragger>
              {videoPreview && (
                <Card
                  hoverable
                  className="video-cards"
                  cover={
                    <>
                      {/* {detailVideoId && ( */}
                      <span className="top-cancel-btn">
                        <CloseOutlined onClick={resetDragger} />
                      </span>
                      {/* )} */}
                      {detailImgSrc ? (
                        <div
                          style={{
                            overflow: "hidden",
                            height: "250px",
                            borderRadius: 8,
                          }}
                        >
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            className="img"
                            src={detailImgSrc}
                          />
                        </div>
                      ) : (
                        <>
                          <span className="top-cancel-btn">
                            <CloseOutlined onClick={resetDragger} />
                          </span>
                          <div
                            id="video-container"
                            style={{ height: percentage === 100 ? 235 : 235 }}
                          >
                            {percentage !== 100 && (
                              <div
                                className="progress-div"
                                style={{
                                  zIndex: percentage !== "100" ? "1" : "0",
                                  cursor: "default",
                                }}
                              >
                                {" "}
                                <Progress
                                  percent={percentage}
                                  status="active"
                                  className="custom-progress-bar"
                                />
                              </div>
                            )}
                            {videoUrl && (
                              <video
                                width="400"
                                controls
                                height="100%"
                              >
                                <source src={URL.createObjectURL(videoUrl)} />
                              </video>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  }
                >
                  <div
                    className="name"
                    title={
                      videoUrl
                        ? videoUrl.name
                        : detailImageUrl && detailImageUrl.name
                    }
                  >
                    {videoUrl
                      ? videoUrl.name
                      : detailImageUrl && detailImageUrl.name}
                  </div>
                  {/* {!detailVideoId ? (
                    <span style={{ bottom: 21 }} className="expand-video">
                      <CloseOutlined onClick={resetDragger} />
                    </span>
                  ) : (
                    <span onClick={handleFullscreen} className="expand-video">
                      <img
                        src={Expand}
                        style={{ fontSize: "2rem", color: "white" }}
                      />
                    </span>
                  )} */}
                  {/* {detailVideoId && (
                    <div className="size">
                      {videoUrl
                        ? (videoUrl && videoUrl.size / 1024 ** 2)
                        .toFixed(1) 
                        +
                          "MB"
                        : (
                            detailImageUrl && detailImageUrl.size / 1000
                          ).toFixed(1) + "KB"}
                    </div>
                  )} */}
                </Card>
              )}
            </div>
          ) : (
            <Form.Item
              {...formItemLayout}
              style={{ marginTop: "32px" }}
              name="media_url"
              label="URL"
              rules={[
                {
                  required: false,
                },
                {
                  type: "url",
                  message: "Please enter valid url",
                },
              ]}
            >
              <Input placeholder="Please enter url" />
            </Form.Item>
          )}
        </Form.Item>
          <>
            <Form.Item
              {...formItemLayout}
              name="instructions"
              className="task-title"
              label="Instructions"
            >
              <TextArea
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Provide a short description or instructions for your group members to follow..."
                autoSize={{
                  minRows: 5,
                  maxRows: 7,
                }}
              />
            </Form.Item>
            {uploadError === true && (
              <span className="error-message">
                Please upload media or enter instructions.
              </span>
            )}
          </>
        <Form.Item
          {...formItemLayout}
          name="task_type"
          label="Task Type"
          className="media-div"
          style={{ marginTop: 8 }}
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Task Type</h3>
                <p>
                  <span>Training Task: </span>group members receive credit for
                  completing the task.
                </p>
                <p>
                  <span>Scored Task: </span>group members receive or provide
                  custom point totals for each submission.
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setTaskType(e.target.value)}
            value={taskType}
            className="custom-radio"
          >
            <Radio value={false}>Training Task</Radio>
            <Radio value={true} style={{ marginLeft: 16 }}>
              Scored Task
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="video_required"
          label="Video Required?"
          className="media-div"
          style={{ marginTop: 8 }}
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Video Required?</h3>
                <p>
                  <span>Yes: </span>group members record and upload their video
                  submissions. For Scored Tasks, group owners watch these videos
                  and provide custom points totals. For Training Tasks,
                  completion credit is granted upon submission upload, but can
                  be revoked(invalidated) by the group owner.
                </p>
                <p>
                  <span>No: </span> group members self-report their completion
                  (Training Task) or provide their own score (Scored Task)
                  without an accompanying video.{" "}
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setVideoRequired(e.target.value)}
            value={videoRequired}
            className="custom-radio"
          >
            <Radio value={"yes"}>Yes</Radio>
            <Radio value={"no"} style={{ marginLeft: 16 }}>
              No
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="time_limit"
          label="Time Limit"
          className={
            "time-limit-div " + (videoRequired === "no" ? "disable-label" : "")
          }
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Video Time Limit</h3>
                <p>
                  <span>Record until completed: </span>the app records until the
                  group member manually stops recording (maximum 15 minutes).
                </p>
                <p>
                  <span>Stop recording after: </span>the app stops recording at
                  the specified limit.
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setTimeLimit(e.target.value)}
            value={timeLimit}
            className="custom-radio"
            disabled={videoRequired === "no"}
          >
            <Radio value={false}>
              No time limit-record until task is completed
            </Radio>
            <div style={{ display: "flex" }}>
              <Radio value={true} style={{ width: 180 }}>
                Stop recording after
              </Radio>

              <Select
                placeholder="Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{
                  marginTop: "8px",
                  width: 140,
                  height: 30,
                  zIndex: 9,
                }}
                popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
                suffixIcon={<img src={DownArrow} />}
                size={"large"}
                className="select-input"
                options={[
                  {
                    label: "30sec",
                    value: "30",
                  },
                  {
                    label: "1min",
                    value: "60",
                  },
                  {
                    label: "1min 30sec",
                    value: "90",
                  },
                  {
                    label: "2min",
                    value: "120",
                  },
                  {
                    label: "2min 30sec",
                    value: "150",
                  },
                  {
                    label: "3min",
                    value: "180",
                  },
                  {
                    label: "3min 30sec",
                    value: "210",
                  },
                  {
                    label: "4min",
                    value: "240",
                  },
                  {
                    label: "4min 30sec",
                    value: "270",
                  },
                  {
                    label: "5min",
                    value: "300",
                  },
                  {
                    label: "5min 30sec",
                    value: "330",
                  },
                  {
                    label: "6min",
                    value: "360",
                  },
                  {
                    label: "6min 30sec",
                    value: "390",
                  },
                  {
                    label: "7min",
                    value: "420",
                  },
                  {
                    label: "7min 30sec",
                    value: "450",
                  },
                  {
                    label: "8min",
                    value: "480",
                  },
                  {
                    label: "8min 30sec",
                    value: "510",
                  },
                  {
                    label: "9min",
                    value: "540",
                  },
                  {
                    label: "9min 30sec",
                    value: "570",
                  },
                  {
                    label: "10min",
                    value: "600",
                  },
                  {
                    label: "10min 30sec",
                    value: "630",
                  },
                  {
                    label: "11min",
                    value: "660",
                  },
                  {
                    label: "11min 30sec",
                    value: "690",
                  },
                  {
                    label: "12min",
                    value: "720",
                  },
                  {
                    label: "12min 30sec",
                    value: "750",
                  },
                  {
                    label: "13min",
                    value: "780",
                  },
                  {
                    label: "13min 30sec",
                    value: "810",
                  },
                  {
                    label: "14min",
                    value: "840",
                  },
                  {
                    label: "14min 30sec",
                    value: "870",
                  },
                  {
                    label: "15min",
                    value: "900",
                  },
                ]}
                onChange={(e) => setTimeLimitSeconds(e)}
                disabled={videoRequired === "no"}
              />
            </div>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item
          {...formItemLayout}
          name="task_status"
          label="Task Library Status"
          className="time-limit-div"
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Task Library Status</h3>
                <p>
                  <span>Public: </span>your task is shared with other coaches in
                  the Ballogy Workout Library.
                </p>
                <p>
                  <span>Private: </span>your task can only be used within your
                  Team Training group.
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setTaskStatus(e.target.value)}
            value={taskStatus}
            className="custom-radio"
          >
            <Radio value={"public"}>Public</Radio>
            <Radio value={"private"}>Private</Radio>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          {...formItemLayout}
          name="level"
          label="Skill Level"
          className="media-div"
        >
          <Radio.Group
            onChange={(e) => setLevel(e.target.value)}
            value={level}
            // defaultValue={value}
            className="custom-radio"
          >
            <Radio value={"beginner"}>Beginners</Radio>
            <Radio value={"intermediate"}>Intermediate</Radio>
            <Radio value={"advanced"}>Advance</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="tags"
          label="Tagging"
          className="media-div"
          rules={[
            {
              required: true,
              message: "Please select tag(s)",
            },
          ]}
        >
          <Checkbox.Group
            onChange={onChangeTags}
            value={value}
            // defaultValue={value}
            className="custom-checkbox"
            options={tagOptions}
          >
          </Checkbox.Group>
        </Form.Item>
        <Form.Item style={{ display: "flex", gap: 8 }}>
          <Button
            //  htmlType="submit"
            type="default"
            className="discard-btn"
            onClick={()=>setNewTaskModal(false)}
            style={{color:color , background : lightButtonBackground}}
          >
            Cancel
          </Button>
          <Button
            className="dark-base-btn"
            type="primary"
            htmlType="submit"
            style={{background:darkButtonBackground}}
            disabled={uploadingStart === true}
          >
            {/* Add & Next */}
            Create Task
          </Button>
        </Form.Item>
      </Form>

      {/* <TaskList /> */}
    </Modal>
  );
};

export default NewTask;
