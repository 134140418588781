import { Col, Row, Progress, Spin, Skeleton } from "antd";
import { useContext, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { getDate } from "../../../util/config";
import { UserContext } from "../../../context/user-context";
import Avatar from "react-avatar";
import { PrivateWorkoutsContext } from "../../../coach-context/group-context/private-workouts";
import WorkoutTasks from "./workout-tasks";
const MemberWorkouts = () => {
  const {
    profileWorkoutData,
    loading,
    imageLoaded,
    setImageLoaded,
    profileWorkoutDetail,
    memberWorkouts,
    setMemberWorkouts
  } = useContext(PrivateWorkoutsContext);
  const { selectedSport, color } = useContext(UserContext);
  const [workoutTasksView, setWorkoutTasksView] = useState(false);
  let activeWorkouts = [],
    archiveWorkouts = [],
    upcomingWorkouts = [];
  profileWorkoutData.results?.length > 0 &&
    profileWorkoutData.results?.map(function (item) {
      if (item?.category === "active") {
        activeWorkouts.push(item);
      } else if (item?.category === "archive") {
        archiveWorkouts.push(item);
      } else {
        upcomingWorkouts.push(item);
      }
    });
  return (
    <div className="workouts-list-main">
      <div style={{ display: "flex" }}>
      {memberWorkouts ===true &&  workoutTasksView === false &&
      <FaArrowLeftLong
          className="left-arrow"
          style={{ margin: "10px 10px 10px 0px", color: color, stroke: color }}
          onClick={() => {
            setMemberWorkouts(false)
          }}
        />
      } 
        {profileWorkoutData?.profile?.avatar !== null && imageLoaded === "" && (
          <Skeleton.Image active={true} className="user-skeleton" />
        )}
        {!profileWorkoutData?.profile?.avatar ? (
          <Avatar
            name={profileWorkoutData?.profile?.name}
            color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
            className="user-avatar"
          />
        ) : (
          <img
            src={profileWorkoutData?.profile?.avatar}
            style={{
              width: 40,
              height: 40,
              display: imageLoaded === "" ? "none" : "block",
              borderRadius: 6,
            }}
            onLoad={() => setImageLoaded("loaded")}
          ></img>
        )}
        <div className="name-div">
          <h3 className="user-name">{profileWorkoutData?.profile?.name}</h3>
          <h4 className="user-handle">
            @{profileWorkoutData?.profile?.username}
          </h4>
        </div>
      </div>
      <hr />
      {workoutTasksView === false ? (
        <div style={{ position: "relative" }}>
          {loading === true ? (
            <Spin
              className={
                "loader " +
                (selectedSport === 1
                  ? "orange-theme-loader"
                  : "blue-theme-loader")
              }
              size="large"
              style={{
                position: "absolute",
                left: "49%",
                marginTop: "5%",
              }}
            />
          ) : (
            <>
              <div style={{ display: "flex" }}>
                <h2>Active Workouts</h2>
              </div>
              <div className="workout-count" style={{ margin: "0" }}>
                *Excludes Team Training Group Workouts
              </div>

              <Row gutter={16} className="challenges-row">
                {activeWorkouts && activeWorkouts.length > 0 ? (
                  activeWorkouts.slice(0, 3).map(function (item, index) {
                    return (
                      <Col
                        className="gutter-row"
                        span={8}
                        key={index}
                        onClick={() => {
                          profileWorkoutDetail(item.id);
                          setWorkoutTasksView(true);
                        }}
                      >
                        <div
                          style={{ padding: "0 5px", cursor: "pointer" }}
                          className="div-1"
                        >
                          <h2>{item?.title}</h2>
                          <span>
                            Starts:
                            {getDate(item?.start_date)}
                          </span>
                          <div style={{ display: "flex", marginTop: "40px" }}>
                            <div className="percentage">
                              {item?.completion_percentage}%
                            </div>
                          </div>
                          <Progress
                            percent={item.completion_percentage}
                            showInfo={false}
                            className={
                              selectedSport === 1
                                ? "orange-progress-bar"
                                : "blue-progress-bar"
                            }
                          />
                          {item?.recent_submission === true ? (
                            <span style={{ color: "#F15A29" }}>
                              {" "}
                              New Rep(s)
                            </span>
                          ) : (
                            <span style={{ color: "#5e6278" }}>
                              No New Rep(s)
                            </span>
                          )}
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <span className="no-result">
                    There are no active workouts
                  </span>
                )}
              </Row>
              <div style={{ display: "flex" }}>
              <h2>Archived Workouts</h2>
              </div>
              <Row className="challenges-row" gutter={16}>
                {archiveWorkouts && archiveWorkouts.length > 0 ? (
                  archiveWorkouts.slice(0, 3).map(function (item, index) {
                    return (
                      <Col
                        className="gutter-row"
                        span={8}
                        key={index}
                        onClick={() => {
                          setWorkoutTasksView(true);
                          profileWorkoutDetail(item.id);
                        }}
                      >
                        <div
                          style={{ padding: "0 5px", cursor: "default" }}
                          className="div-1"
                        >
                          <h2>{item?.title}</h2>
                          <span>
                            Starts:
                            {getDate(item?.start_date)}
                          </span>
                          <div style={{ display: "flex", marginTop: "40px" }}>
                            <div className="percentage">
                              {item?.completion_percentage}%
                            </div>
                          </div>
                          <Progress
                            percent={item?.completion_percentage}
                            showInfo={false}
                            className={
                              selectedSport === 1
                                ? "orange-progress-bar"
                                : "blue-progress-bar"
                            }
                          />
                          {item?.recent_submission === true ? (
                            <span style={{ color: "#F15A29" }}>
                              {" "}
                              New Rep(s)
                            </span>
                          ) : (
                            <span style={{ color: "#5e6278" }}>
                              No New Rep(s)
                            </span>
                          )}
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <span className="no-result">
                    There are no archived workouts
                  </span>
                )}
              </Row>
            </>
          )}
        </div>
      ) : (
        <WorkoutTasks setWorkoutTasksView={setWorkoutTasksView} />
      )}
    </div>
  );
};
export default MemberWorkouts;
