// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-workout-assignment {
  background: #fff;
  padding: 30px 100px;
  text-align: left;
  border-radius: 6px;
  width: 100%;
}
.edit-workout-assignment .list, .edit-workout-assignment .members-div {
  width: 635px;
}
.edit-workout-assignment .heading-row {
  display: flex;
  margin-bottom: 40px;
}
.edit-workout-assignment .heading-row span {
  padding: 33px 0px 8px 30px;
}
.edit-workout-assignment .heading-row h1 {
  margin: 0;
  color: var(--gray-gray-900, #181c32);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.22px;
  margin-left: 16px !important;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/coach-module/Groups/group-detail/edit-workout-assignment/edit-workout-assignment.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACJ;AAAI;EACI,YAAA;AAER;AAAI;EACI,aAAA;EACA,mBAAA;AAER;AAAQ;EACE,0BAAA;AAEV;AACQ;EACE,SAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,4BAAA;EACA,UAAA;AACV","sourcesContent":[".edit-workout-assignment{\n    background: #fff;\n    padding: 30px 100px;\n    text-align: left;\n    border-radius: 6px;\n    width: 100%;\n    .list , .members-div{\n        width: 635px;\n    }\n    .heading-row {\n        display: flex;\n        margin-bottom: 40px;\n    \n        span {\n          padding: 33px 0px 8px 30px;\n        }\n    \n        h1 {\n          margin: 0;\n          color: var(--gray-gray-900, #181c32);\n          font-size: 22px;\n          font-style: normal;\n          font-weight: 600;\n          line-height: 22px;\n          letter-spacing: -0.22px;\n          margin-left: 16px !important;\n          padding: 0;\n        }\n      }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
