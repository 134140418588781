import { useContext, useEffect, useState } from "react";
import {
    Modal,
    Row,
    Col,
} from "antd";
import { UserContext } from "../../../context/user-context";
import OvalLogo from "../../../assets/images/oval-logo.svg"
import BlueOvalLogo from "../../../assets/images/Group 5.svg"
const CategoryModal = ({ categoryModal, setCategoryModal , selectedCategory, setSelectedCategory , categoryType ,setFeaturedWorkoutModal , getAllFeatureWorkouts }) => {
    const {selectedSport , allTags ,darkButtonBackground , getAllTags} = useContext(UserContext)
    useEffect(()=>{
        if(categoryType === "task"){
            getAllTags(2)
        }else{
            getAllTags(1)
        }
    },[])
    return (
        <Modal
            className="category-modal"
            wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
            open={categoryModal}
            //   onOk={handleOk}
            onCancel={() => setCategoryModal(false)}
            okText={"Finish Editing"}
            cancelText={"Cancel"}
            width={"769px"}
            footer={false}
        >
            <h1>Select {categoryType === "task" ? "Task" : "Workout"} category</h1>
            <p>Select category below to assign existing {categoryType === "task" ? "Task" : "Workout"}</p>
            <Row gutter={[14, 14]}>
                <Col className="gutter-row" span={8} onClick={() => { setSelectedCategory(""); setCategoryModal(false) }}>
                    <div className={"card " + (selectedCategory === "" ? "bordered" : "")} style={{ color: "#3f4254" }}>
                        All {categoryType === "task" ? "Tasks" : "Workouts"}
                    </div>
                </Col>
                {/* {categoryType !== "task" && 
                <Col className="gutter-row" span={8} onClick={() => {setCategoryModal(false);setFeaturedWorkoutModal(true);getAllFeatureWorkouts();setSelectedCategory("featured") }}>
                    <div className={"card " + (selectedCategory === "" ? "bordered" : "")} style={{ color: "#f9f9f9" , background:darkButtonBackground,flexDirection:"column"}}>
                        <img src={selectedSport === 1 ? OvalLogo : BlueOvalLogo} style={{width:65 , height:65}}/>
                    Featured Workouts
                    </div>
                </Col>
                } */}
                {allTags.map((item, index) => {
                    return (
                      <Col
                        className={"gutter-row"}
                        span={8}
                        key={index}
                        onClick={() => {
                          setSelectedCategory(item.tag);
                          setCategoryModal(false);
                        }}
                      >
                        <div
                          style={{
                            backgroundImage:
                              item.id === 26 ? "" : `url(${item.image})`,
                            background:
                              item.id === 26
                                ? darkButtonBackground
                                : `url(${item.image})`,
                            flexDirection: "column",
                          }}
                          className={
                            "card " +
                            (selectedCategory === item.tag ? "bordered" : "")
                          }
                        >
                          {item.id === 26 ? (
                            <div
                              style={{
                                backgroundImage:
                                  selectedSport === 1
                                    ? `url(${OvalLogo})`
                                    : `url(${BlueOvalLogo})`,
                              }}
                              className="feature-bg"
                            >
                              {" "}
                              <span>{item.tag}</span>{" "}
                            </div>
                          ) : (
                            item.tag
                          )}
                        </div>
                      </Col>
                    );})}
            </Row>
        </Modal>
    );
};
export default CategoryModal;
