import React, { useContext, useRef } from "react";
import Play from "../../../assets/images/play.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { UserContext } from "../../../context/user-context";
import { Link, useLocation } from "react-router-dom";
import { PrivateWorkoutsContext } from "../../../context/group-context/private-workouts";
import { Col, Row, Card, Empty, Table, Progress } from "antd";
import {
  getDateWithTimeWithoutDay,
  getDateWithoutDay,
} from "../../../util/config";
import SubmissionDetailModal from "./submission-detail-modal";
import ReactPlayer from "react-player";
import Thumbnail from "../../../assets/images/chatLogo.svg";

const style = {
  padding: "5px 0",
  textAlign: "left",
};

const WorkoutTaskDetail = ({ setShowTaskDetail }) => {
  const vidRef = useRef(null);
  const { color } = useContext(UserContext);
  const location = useLocation();
  const {
    workoutDetail,
    taskDetail,
    setSelectedSubmission,
    setSubmissionDetailModal,
    getSubmissionDetail,
    submissionDetailModal
  } = useContext(PrivateWorkoutsContext);
  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if (crumb !== "home" && crumb !== "groups") {
        let newCrumb = crumb.replace("-", " ");
        return (
          <div className="crumb" key={newCrumb}>
            <Link
              // to={currentLink}
              style={{ cursor: "text" }}
            >
              {decodeURI(newCrumb)}
            </Link>
          </div>
        );
      }
    });
  function secondsToHms(d) {
    d = Number(d);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    return mDisplay + sDisplay;
  }
  const trainingTaskColumns = [
    {
      title: "Submission",
      dataIndex: "member",
      width: "30%",
      render: (text, record, index) => (
        <div span={20} className="member-div">
          {record.title}
          <span className="task-type">Submission {index + 1}</span>
        </div>
      ),
    },
    {
      title: "Completed",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        <span>{getDateWithTimeWithoutDay(record.created_at)}</span>
      ),
    },
    {
      render: (record) => (
        <div style={{ display: "flex", cursor: "pointer" }}>
          <div
            style={{ color: color }}
            onClick={() => {
              setSelectedSubmission(record.id);
              setSubmissionDetailModal(true);
              getSubmissionDetail(record.id);
            }}
            className="view-submissions"
          >
            View submission
          </div>
        </div>
      ),
    },
  ];
  const scoredTaskColumns = [
    {
      title: "Submission",
      dataIndex: "member",
      width: "30%",
      render: (text, record, index) => (
        <div span={20} className="member-div">
          {record.title}
          <span className="task-type">Submission {index + 1}</span>
        </div>
      ),
    },
    {
      title: "Completed",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        <span>{getDateWithTimeWithoutDay(record.created_at)}</span>
      ),
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text, record) => <span>{record.score}</span>,
    },
    {
      render: (record) => (
        <div style={{ display: "flex", cursor: "pointer" }}>
          <div
            style={{ color: color }}
            onClick={() => {
              setSelectedSubmission(record.id);
              setSubmissionDetailModal(true);
              getSubmissionDetail(record.id);
            }}
            className="view-submissions"
          >
            View submission
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="task-detail-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          style={{ margin: "33px 0 8px", color: color, stroke: color }}
          onClick={() => {
            setShowTaskDetail(false);
          }}
        />
        <h1>{workoutDetail?.workout?.title}</h1>
      </div>
      <div className="breadcrumbs">
        <div style={{ display: "flex" }}>
          {crumbs}
          <div className="crumb" style={{ cursor: "text" }}>
            <a style={{ cursor: "text" }}>{workoutDetail?.workout?.title}</a>
          </div>
        </div>
      </div>
      <Row className="creator" style={{ marginTop: 25 }}>
        <Col span={4} className="label">
          <h3 className="label">Task Level</h3>
        </Col>
        <Col
          span={20}
          className="value"
          style={{ textTransform: "capitalize" }}
        >
          <h3 className="user-name">{taskDetail?.task?.skill_level}</h3>
        </Col>
        <Col span={4} className="label">
          <h3 className="label">Task Type</h3>
        </Col>
        <Col span={20} className="value">
          <h3 style={{ margin: 0 }} className="user-name">
            {taskDetail?.task?.media_required === true &&
            taskDetail?.task?.scored_task === true
              ? "Video Scored Task"
              : taskDetail?.task?.media_required === true &&
                taskDetail?.task?.scored_task === false
              ? "Video Training Task"
              : taskDetail?.task?.media_required === false &&
                taskDetail?.task?.scored_task === true
              ? "No Video Scored Task"
              : "No Video Training Task"}
          </h3>
        </Col>{" "}
        <Col span={4} className="label">
          <h3 className="label">Time Limit</h3>
        </Col>
        <Col span={20} className="value">
          <h3 className="user-name">
            {" "}
            {taskDetail?.task?.is_time_limit === true
              ? secondsToHms(taskDetail?.task?.time_limit_seconds)
              : "No time limit"}
          </h3>
        </Col>
        <Col span={4} className="label">
          <h3 className="label">Task Instructions</h3>
        </Col>
        <Col span={20} className="value">
          <h3 className="user-name">
            {taskDetail?.task?.media_url !== null ? (
              <Card hoverable className="media-card">
                <>
                  <div id="video-container">
                    <ReactPlayer
                      ref={vidRef}
                      url={taskDetail?.task?.media_url}
                      controls
                      playing
                      width={"100%"}
                      height={"100%"}
                      light={taskDetail?.task?.thumbnail}
                      playIcon={<img src={Play} style={{ width: 35 }} />}
                    />
                  </div>
                </>
              </Card>
            ) : taskDetail?.task?.instruction !== null ? (
              taskDetail?.task?.instruction
            ) : (
              "N/A"
            )}
          </h3>
        </Col>
        <div className="submissions">
          <div style={{ display: "flex" }}>
            <h3>Submissions</h3>
          </div>
          {/* <div className="label" style={{ margin: "10px 0" }}>
            You have completed {taskDetail?.task?.num_completion}
          </div> */}

          {taskDetail?.task?.media_required === false ? (
            <Table
              columns={
                taskDetail?.task?.media_required === false &&
                taskDetail?.task?.scored_task === false
                  ? trainingTaskColumns
                  : scoredTaskColumns
              }
              pagination={false}
              dataSource={taskDetail?.task?.submissions}
              className="tasks-table"
            />
          ) : (
            <Row
              style={{ display: "flex" }}
              className="groups-row submission-video"
              gutter={[34, 34]}
            >
              {taskDetail?.task?.submissions?.length > 0 ? (
                taskDetail?.task?.submissions.map((item, index) => {
                  return (
                    <Col
                      className="gutter-row"
                      span={6}
                      style={{ paddingBottom: 16 }}
                      key={index}
                    >
                      <div style={style} className="div-1">
                        <Card
                          hoverable
                          className="video-card"
                          style={{
                            cursor:
                              item.is_reviewed === true ||
                              item.status === "live"
                                ? "pointer"
                                : "default",
                          }}
                          onClick={
                            item.status === "live"
                              ? () => {
                                  setSelectedSubmission(item.id);
                                  setSubmissionDetailModal(true);
                                  getSubmissionDetail(item.id);
                                }
                              : null
                          }
                          cover={
                            <>
                              <div id="video-container">
                                {item?.media_thumbnail ? (
                                  <div
                                    style={{
                                      display: "grid",
                                      placeItems: "center",
                                    }}
                                  >
                                    <img
                                      src={item.media_thumbnail}
                                      style={{
                                        width: "100%",
                                        height: "164px",
                                      }}
                                    />
                                    <img
                                      src={Play}
                                      style={{
                                        width: 35,
                                        position: "absolute",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      placeItems: "center",
                                      height: "100%",
                                    }}
                                  >
                                    <img
                                      src={Thumbnail}
                                      style={{
                                        width: "40%",
                                        marginTop: "12%",
                                      }}
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </>
                          }
                        >
                          {taskDetail?.task?.scored_task === true && (
                            <div>
                              <div className="score">
                                Score:{" "}
                                {item.score === 0 &&
                                item.is_reviewed === true ? (
                                  //  || item.score
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.score}
                                  </span>
                                ) : (
                                  <span style={{ fontWeight: "bold" }}>
                                    N/A
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          <div
                            className="date"
                            style={{
                              marginTop:
                                taskDetail?.task?.scored_task === false
                                  ? 20
                                  : 0,
                            }}
                          >
                            Submitted:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "#181c32" }}
                            >
                              {getDateWithoutDay(item.created_at)}
                            </span>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ margin: "70px auto" }}
                />
              )}
            </Row>
          )}
        </div>
      </Row>
      {submissionDetailModal === true &&
      <SubmissionDetailModal scoredTask={taskDetail?.task?.scored_task} />
}
    </div>
  );
};

export default WorkoutTaskDetail;
