import { Button, Modal, Spin } from "antd";
import React, { useContext, useEffect } from "react";
import ReactPlayer from "react-player";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { UserContext } from "../../../context/user-context";
import { PrivateWorkoutsContext } from "../../../context/group-context/private-workouts";
const SubmissionDetailModal = ({ scoredTask }) => {
  const { selectedSport } = useContext(UserContext);
  const {
    submissionDetailModal,
    setSubmissionDetailModal,
    submissionDetail,
    setSubmissionDetail,
    loading,
    setSelectedSubmission,
    imageLoaded,
    setImageLoaded,
    groupSubmissionView
  } = useContext(PrivateWorkoutsContext);

  const cancelModal = () => {
    setSubmissionDetailModal(false);
    setSubmissionDetail({});
    setSelectedSubmission("");
  };
  useEffect(()=>{
    if(Object.keys(submissionDetail).length > 0){
    groupSubmissionView()
    }
  }
  ,[submissionDetail])
  return (
    <Modal
      className="submission-detail-modal"
      rootClassName={
        "submission-detail-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={submissionDetailModal}
      onCancel={() => cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      destroyOnClose={true}
      footer={false}
    >
      {Object.keys(submissionDetail).length > 0 ? (
        <div>
          <div style={{ display: "flex" }}>
            {submissionDetail?.profile?.avatar === null ? (
              <Avatar
                name={submissionDetail?.profile?.name}
                color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                maxInitials={2}
                className="profile-avatar"
              />
            ) : (
              <img
                src={submissionDetail?.profile?.avatar}
                className="profile-logo"
                style={
                  imageLoaded === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
                onLoad={() => setImageLoaded("loaded")}
              />
            )}
            <div className="name-div">
              <h3 className="user-name">{submissionDetail.profile?.name}</h3>
              <h4 className="user-handle">
                @{submissionDetail.profile?.username}
              </h4>
            </div>
            <div style={{ marginLeft: "auto" }} className="submitted-date">
              <h3 className="user-name">Submitted</h3>
              <h4 className="user-handle">
                {getDateWithTimeWithoutDay(
                  submissionDetail?.submission?.created_at
                )}
              </h4>
            </div>
          </div>
          {submissionDetail?.submission?.task_media !== null && (
            <ReactPlayer
              url={submissionDetail?.submission?.task_media}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
            />
          )}

          <div className="submitted-task-detail" style={{ marginTop: 30 }}>
            <div className="task-header">
              {!scoredTask ? <h2>Training Task</h2> : <h2>Scored Task</h2>}
              {scoredTask === true &&
                submissionDetail?.submission?.is_reviewed === false &&
                submissionDetail?.submission?.is_validated !== false && (
                  <div className="score">
                    <h2 style={{ color: "#5e6278" }}>Score:</h2>
                    <span className="score-value">
                      {submissionDetail?.submission?.score}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : (
        loading === true && (
          <Spin
            className={
              "loader " +
              (selectedSport === 1
                ? "orange-theme-loader"
                : "blue-theme-loader")
            }
            size="large"
            style={{
              position: "absolute",
              left: "49%",
            }}
          />
        )
      )}
    </Modal>
  );
};
export default SubmissionDetailModal;
