import React, { useEffect, useState , useContext } from "react";
import {
  deleteItemTraining,
  formPostDataTraining,
  getDataTrainingCoach,
  jsonPostDataTraining,
} from "../util/coach-api-calls";
import { Form, message } from "antd";
import { UserContext } from "../context/user-context";
export const NewWorkoutContext = React.createContext({});
export const NewWorkoutContextProvider = ({ children = {} }) => {
  const {selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  let groupName = window.location.pathname.slice(
    window.location.pathname.lastIndexOf("/") + 1,
    window.location.pathname.length
  );
  const [detailVideoId, setDetailVideoId] = useState("");
  const [mediaType, setMediaType] = useState("upload");
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [title, setTitle] = useState("");
  const [privacyModal, setPrivacyModal] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [newTaskArray, setNewTaskArray] = useState([]);
  const [data, setData] = useState(newTaskArray);
  const [timeLimitSeconds, setTimeLimitSeconds] = useState("");
  const [existingTaskModal, setExistingTaskModal] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [detailImgSrc, setDetailImgSrc] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [existingTasks, setExistingTasks] = useState([]);
  const [skillLevel, setSkillLevel] = useState("");
  const [uploadError, setUploadError] = useState(false);
  const [taskType, setTaskType] = useState(true);
  const [selectedTask, setSelectedTask] = useState({});
  const [createdWorkout, setCreatedWorkout] = useState({});
  const [membersView, setMembersView] = useState(false);
  const [error, setError] = useState(false);
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [current, setCurrent] = useState();
  const createNewTask = (values) => {
    let instructionError =
      values.instructions === undefined || values.instructions === ""
        ? true
        : false;
    if (
      mediaType === "upload" &&
      instructionError === true &&
      detailVideoId === "" &&
      !detailImageUrl
    ) {
      setUploadError(true);
    } else {
      setUploadError(false);
      let formData = new FormData();
      formData.append("title", values.task_title);
      formData.append("order", newTaskArray.length + 1);
      if (detailVideoId) {
        formData.append("media_uri", detailVideoId);
      }
      if (detailImageUrl) {
        formData.append("file", detailImageUrl);
      }
      if (values.media_url) {
        formData.append("media_url", values.media_url);
      }
      formData.append(
        "media_type",
        detailVideoId
          ? "vimeo"
          : values.media_url
          ? "embedded"
          : detailImageUrl
          ? "image"
          : undefined
      );
      formData.append(
        "media_required",
        values.video_required === "yes" ? true : false
      );
      formData.append("instruction", values.instructions);
      formData.append(
        "is_public",
        // values.task_status === "public" ? true : 
        "false"
      );
      formData.append("skill_level", values.level);
      formData.append(
        "is_time_limit",
        values.time_limit === true ? true : false
      );
      formData.append("time_limit_seconds", "30");
      formData.append(
        "score_based_test",
        values.task_type === true ? true : false
      );
      formData.append("tags", tags.toString());
      formData.append("group_id", params.id);
      formData.append("group_name", decodeURI(groupName));
      formData.append("num_completion", 1);
      formData.append("legacy_point", 0);
      formData.append("allow_more_reps", false);
      formData.append("lock", false);
      formData.append("best_score", 100);
      formPostDataTraining(`task`, formData)
        .then((res) => {
          setNewTaskArray((prevTask) => [...prevTask, res.data]);
          setNewTaskModal(false);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const createWorkout = (curriculum_group) => {
    if (title === "") {
      setError(true);
    } else {
      setError(false);
      const list_tasks = newTaskArray.filter( (ele, ind) => ind === newTaskArray.findIndex( elem =>  elem.id === ele.id)).map((e, index) => ({
        id: e.id,
        order: index + 1,
      //   lock: e.lock,
      lock : current === index || index > current && index !== 0 ? true : false,
        num_completion: isNaN(e.num_completion) ? 1 : e.num_completion,
        allow_more_reps: e.allow_more_reps,
        is_cw:curriculum_group
      }));
      let workoutData = JSON.stringify({
        title: title,
        is_public: privacy,
        group_id: params.id,
        group_name: decodeURI(groupName),
        list_tasks: list_tasks,
        is_cw:curriculum_group
      });
      jsonPostDataTraining(`workout`, workoutData)
        .then((res) => {
          setCreatedWorkout(res.data);
          setNewTaskArray((newTaskArray) => []);
          setMembersView(true);
        })
        .catch((err) => {
          console.log(err);
          message.error({
            content: err.response.data.detail,
          });
        });
    }
  };
  const deleteTask = (id) => {
    deleteItemTraining(`task/${id}`)
      .then((res) => {
        if (res && res.status === 200) {
          message.success({
            content: "Task deleted successfully",
          });
          const updatedArray = newTaskArray.filter((item) => item.id !== id);
          setNewTaskArray(updatedArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    if (existingTaskModal) {
    }
  }, [skillLevel, taskType, sort, selectedCategory]);
  useEffect(() => {
    if (!existingTaskModal) {
      setExistingTasks((existingTasks) => []);
    } else {
    }
  }, [existingTaskModal]);
  const defaultContext = {
    createNewTask,
    detailVideoId,
    setDetailVideoId,
    setTags,
    mediaType,
    membersView,
    createdWorkout,
    setMediaType,
    timeLimitSeconds,
    setTimeLimitSeconds,
    setError,
    error,
    newTaskArray,
    deleteTask,
    newTaskModal,
    setNewTaskModal,
    existingTaskModal,
    setExistingTaskModal,
    form,
    detailImgSrc,
    setDetailImgSrc,
    detailImageUrl,
    setDetailImageUrl,
    createWorkout,
    setTitle,
    data,
    setData,
    uploadError,
    existingTasks,
    setSkillLevel,
    setTaskType,
    setSort,
    setSelectedCategory,
    setNewTaskArray,
    setPrivacyModal,
    privacyModal,
    privacy,
    setPrivacy,
    editTaskModal,
    setEditTaskModal,
    selectedTask,
    setSelectedTask,
    params,
    setMembersView,
    current,
    setCurrent
  };
  return (
    <NewWorkoutContext.Provider value={defaultContext}>
      {children}
    </NewWorkoutContext.Provider>
  );
};
