import React, { useEffect, useState } from "react";
import {
  getDataTrainingCoach,
} from "../../util/coach-api-calls";
export const PrivateWorkoutsContext = React.createContext({});
export const PrivateWorkoutsContextProvider = ({ children = {} }) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [privateWorkoutData, setPrivateWorkoutData] = useState({});
  const [profileWorkoutData, setProfileWorkoutData] = useState({});
  const [workoutDetail, setWorkoutDetail] = useState({});
  const [submissionDetail, setSubmissionDetail] = useState({});
  const [selectedSubmission, setSelectedSubmission] = useState("");
  const [submissionDetailModal, setSubmissionDetailModal] = useState(false);
  const [taskDetail, setTaskDetail] = useState({});
  const [memberWorkouts, setMemberWorkouts] = useState(false);
  const [search, setSearch] = useState("");
  const [imageLoaded, setImageLoaded] = useState("");
  const [loading, setLoading] = useState(false);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const getPrivateWorkoutList = () => {
    setLoading(true);
    getDataTrainingCoach(
      `my-workout/group/${params.id}/leaderboard?search=${search}&offset=${offset}&limit=${limit}`
    )
      .then((res) => {
        setPrivateWorkoutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const profileWorkoutList = (profileId) => {
    setLoading(true);
    getDataTrainingCoach(`my-workout/group/${params.id}/profile/${profileId}/list`)
      .then((res) => {
        setProfileWorkoutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const profileWorkoutDetail = (workoutId) => {
    setLoading(true);
    getDataTrainingCoach(`my-workout/${workoutId}/profile/${profileWorkoutData?.profile?.id}/details?group_id=${params.id}`)
      .then((res) => {
        setWorkoutDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const workoutTaskDetail = (taskId) => {
    setLoading(true);
    getDataTrainingCoach(`my-workout/task/${taskId}/profile/${profileWorkoutData?.profile?.id}/details?group_id=${params.id}`)
      .then((res) => {
        setTaskDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSubmissionDetail = (submissionId) => {
    setLoading(true);
    getDataTrainingCoach(
      `my-workout/submission/${submissionId}/profile/${profileWorkoutData?.profile?.id}/details?group_id=${params.id}`
    )
      .then((res) => {
        setSubmissionDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const groupSubmissionView = () => {
    getDataTrainingCoach(`my-workout/submission/${submissionDetail.submission.id}/group/${params.id}/view`).
    then((res)=>{
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(() => {
    getPrivateWorkoutList();
  }, [search, offset]);
  const defaultContext = {
    loading,
    privateWorkoutData,
    setPrivateWorkoutData,
    search,
    setSearch,
    offset,
    setOffset,
    limit,
    profileWorkoutList,
    profileWorkoutData,
    imageLoaded,
    setImageLoaded,
    profileWorkoutDetail,
    workoutDetail,
    workoutTaskDetail,
    taskDetail,
    selectedSubmission,
    setSelectedSubmission,
    submissionDetailModal,
    setSubmissionDetailModal,
    getSubmissionDetail,
    submissionDetail,
    setSubmissionDetail,
    memberWorkouts,
    setMemberWorkouts,
    groupSubmissionView
  };
  return (
    <PrivateWorkoutsContext.Provider value={defaultContext}>
      {children}
    </PrivateWorkoutsContext.Provider>
  );
};
