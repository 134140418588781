import React, { useContext } from "react";
import { Tabs } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, Link } from "react-router-dom";
import { UserContext } from "../../../context/user-context";
import { VisionShootingContext } from "../../../context/group-context/vision-shooting-context";
import ShootingDetailModal from "./shooting-detail-modal";
import IndividualShootingSession from "./individual-shooting-sessions";
import TeamShootingSession from "./team-shooting-sessions";
const style = {
  padding: "5px 0",
};
const ShootingDetail = () => {
  const { color, selectedSport } = useContext(UserContext);
  const location = useLocation();
  const {
    shootingDetailModal,
    setShootingDetailView,
    setLeaderboardProfile,
    getVisionLeaderBoard,
    params,
    setActiveTab,
    activeTab , 
    setSessionsData,
    setOffset
  } = useContext(VisionShootingContext);
  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if (crumb !== "home" && crumb !== "groups") {
        let newCrumb = crumb.replace("-", " ");
        return (
          <div className="crumb" key={newCrumb}>
            <Link style={{ cursor: "text" }}>{decodeURI(newCrumb)}</Link>
          </div>
        );
      }
    });
  return (
    <div className="shooting-detail-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          style={{ margin: "0 0 8px 0", color: color, stroke: color }}
          onClick={() => {
            setOffset(0)
            setSessionsData([])
            setShootingDetailView(false);
            setLeaderboardProfile({});
            getVisionLeaderBoard();
          }}
        />
        <h1>All Sessions</h1>
      </div>
      <div className="breadcrumbs">
        <div style={{ display: "flex" }}>
          {crumbs}
          <div className="crumb" style={{ cursor: "text" }}>
            <a style={{ cursor: "text" }}>All Vision Shooting Sessions</a>
          </div>
        </div>
      </div>
      {activeTab === "individual" ? <IndividualShootingSession /> : <TeamShootingSession /> }
      {shootingDetailModal === true && <ShootingDetailModal />}
    </div>
  );
};

export default ShootingDetail;
