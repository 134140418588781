import React, { useContext, useEffect, useState } from "react";
import "./edit-workout.scss";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { FaArrowLeftLong } from "react-icons/fa6";
import Delete from "../../../assets/images/delete.svg";
import DragIndicator from "../../../assets/images/drag_indicator.svg";
import { Button, Input, Checkbox, Switch, Modal } from "antd";
import File from "../../../assets/images/files-folders.svg";
import Plus from "../../../assets/images/plus-red.svg";
import ExistingTask from "../new-workout/existing-task-modal";
import NewTask from "../new-workout/new-task-modal";
import {
  EditWorkoutContext,
  EditWorkoutContextProvider,
} from "../../../coach-context/workouts-context/edit-workout-context";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/user-context";
let totalReps = 0;
const EditWorkoutPage = ({ setWorkoutDetail, setEditWorkout }) => {
  const { color, selectedSport, lightButtonBackground, darkButtonBackground } =
    useContext(UserContext);
  const navigate = useNavigate();
  const {
    existingTaskModal,
    setExistingTaskModal,
    selectedWorkout,
    title,
    setNewTaskModal,
    setNewTaskArray,
    current,
    newTaskArray,
    newTaskModal,
    error,
    setTitle,
    setPrivacy,
    setCurrent,
    setError,
    generalDetail,
    updateExistingWorkout,
    getWorkoutDetail,
    workoutDetail,
  } = useContext(EditWorkoutContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const showExistingTaskModal = () => {
    setExistingTaskModal(true);
  };
  const showNewTaskModal = () => {
    setNewTaskModal(true);
  };
  useEffect(() => {
    setTitle(workoutDetail.title);
    setNewTaskArray(workoutDetail.tasks);
  }, [workoutDetail]);
  const CustomKnob = React.forwardRef((props, ref) => {
    return (
      <Button
        ref={ref}
        icon={
          <img src={DragIndicator} className="drag-icon" draggable={false} />
        }
        style={{ cursor: "grab" }}
      ></Button>
    );
  });
  const onSortEnd = (oldIndex, newIndex) => {
    setNewTaskArray((prevState) => {
      const newItems = [...prevState];
      if (oldIndex > newIndex) {
        for (let i = oldIndex; i >= newIndex; i--) {
          newItems[i].order++;
          newItems[oldIndex].order = newIndex + 1;
        }
      } else if (oldIndex < newIndex) {
        for (let i = oldIndex; i <= newIndex; i++) {
          newItems[i].order--;
          newItems[oldIndex].order = newIndex;
        }
      }
      return newItems.sort((a, b) => a.order - b.order);
    });
  };
  let url = window.location.pathname;
  const showWorkout = () => {
    setEditWorkout(false);
    setWorkoutDetail(true);
  };
  const updateState = (index) => (e) => {
    const newArray = newTaskArray.map((item, i) => {
      if (index === i) {
        if (e.target) {
          return {
            ...item,
            [e.target.name]:
              typeof e.target.value === "string"
                ? parseInt(e.target.value)
                : e.target.checked,
          };
        } else {
          if (e === true) {
            setCurrent(index);
          } else {
            setCurrent();
          }
          return { ...item, lock: e };
        }
      } else {
        return item;
      }
    });
    setNewTaskArray(newArray);
  };
  const handleOk = () => {
    let newArray = newTaskArray.filter((e) => e.id !== deletedItem.id);
    setNewTaskArray(newArray);
    setDeletedItem({});
    setDeleteModal(false);
  };
  const deleteTask = (item) => {
    setDeletedItem(item);
    setDeleteModal(true);
  };

  let taskPrivacyArray = [];
  useEffect(() => {
    setPrivacy(false);
  }, [newTaskArray?.length]);
  totalReps = newTaskArray?.reduce((accumulator, object) => {
    return accumulator + object.num_completion;
  }, 0);
  useEffect(() => {
    if (selectedWorkout) {
      getWorkoutDetail();
    }
  }, [selectedWorkout]);
  return (
    <div className="new-workout-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          alt=""
          onClick={() => showWorkout()}
          style={{ stroke: color, color: color }}
        />
        <h1>Edit workout</h1>
      </div>
      <label htmlFor="category">Workout Title</label>
      <br />
      <Input
        className="category-input"
        onChange={(e) => {
          setError(false);
          setTitle(e.target.value);
        }}
        value={title}
      />
      {error && (
        <span className="error-message" style={{ marginTop: 0 }}>
          Please enter workout title.
        </span>
      )}
      <div className="btn-div">
        <Button
          icon={<img src={File} />}
          className="existing-task-btn"
          onClick={showExistingTaskModal}
        >
          Add from existing tasks
        </Button>
        <Button
          icon={
            <img
              src={Plus}
              className={
                selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon"
              }
            />
          }
          className="new-task-btn"
          onClick={showNewTaskModal}
          style={{
            borderColor: color,
            background: lightButtonBackground,
            color: color,
          }}
        >
          Create a new task
        </Button>
      </div>
      {/* </>
      )} */}
      {newTaskArray && newTaskArray.length > 0 && (
        <div className="workout-overview">
          {!url.includes("challenge") && <h3>Workout Overview</h3>}
          <div className="total">
            Total Tasks
            <span>
              {newTaskArray &&
                newTaskArray.filter(
                  (ele, ind) =>
                    ind === newTaskArray.findIndex((elem) => elem.id === ele.id)
                ).length}
            </span>
            {!url.includes("challenge") && (
              <>
                Total Reps
                <span>{isNaN(totalReps) ? 0 : totalReps}</span>
              </>
            )}
          </div>
          <SortableList
            onSortEnd={onSortEnd}
            className="list"
            draggedItemClassName="dragged"
          >
            {newTaskArray &&
              newTaskArray?.sort((a, b) => a.order - b.order)
                .filter(
                  (ele, ind) =>
                    ind === newTaskArray.findIndex((elem) => elem.id === ele.id)
                )
                .map((item, index) => {
                  return (
                    <SortableItem key={index}>
                      <div
                        className="overview-card"
                        style={{ listStyleType: "none" }}
                      >
                        <div style={{ display: "flex" }}>
                          <h3>{item.title}</h3>
                          <div className="right-div">
                            {/* <Button icon={<img src={Edit} />}></Button> */}
                            {/* <Button
                              icon={<img src={Delete} draggable={false} />}
                              onClick={() => deleteTask(item)}
                            ></Button> */}
                            <SortableKnob>
                              <CustomKnob />
                            </SortableKnob>
                          </div>
                        </div>
                        <div className="form-data">
                          <div className="reps">
                            <label htmlFor="complete-task">
                              Complete task in
                            </label>
                            <br />
                            <Input
                              addonBefore={"Reps"}
                              defaultValue={item.num_completion}
                              className="reps-input"
                              name={"num_completion"}
                              onChange={updateState(index)}
                              min={1}
                              value={
                                item.num_completion > 0
                                  ? item.num_completion
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="extra">
                          <Checkbox
                            className="custom-checkbox"
                            defaultChecked={item.allow_more_reps}
                            checked={item.allow_more_reps}
                            onChange={updateState(index)}
                            name="allow_more_reps"
                          />
                          <span className="allowed">Allowed extra reps.</span>
                          {index !== 0 && (
                            <div className="lock-div">
                              Lock this task
                              <Switch
                                defaultChecked={item.lock}
                                checked={index >= current || item.lock}
                                disabled={index > current}
                                name="lock"
                                onChange={updateState(index)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </SortableItem>
                  );
                })}
          </SortableList>
          <div style={{ marginTop: "24px" }}>
            <Button
              className="cancel-btn"
              onClick={() =>
                navigate({
                  pathname: `/home/workouts`,
                })
              }
            >
              Cancel
            </Button>
            <Button
              className="save-btn"
              onClick={() => updateExistingWorkout(workoutDetail)}
              style={{ background: darkButtonBackground }}
            >
              Update Workout
            </Button>
          </div>
        </div>
      )}
      {existingTaskModal && (
        <ExistingTask
          existingTaskModal={existingTaskModal}
          setExistingTaskModal={setExistingTaskModal}
          setNewTaskArray={setNewTaskArray}
          newTaskArray={newTaskArray}
        />
      )}
            {newTaskModal === true &&
      <NewTask
        newTaskModal={newTaskModal}
        setNewTaskModal={setNewTaskModal}
        newTaskArray={newTaskArray}
        setNewTaskArray={setNewTaskArray}
        generalDetail={generalDetail}
      />
            }
      <Modal
        className="delete-task-modal"
        wrapClassName={
          selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
        }
        open={deleteModal}
        onOk={handleOk}
        onCancel={() => setDeleteModal(false)}
        okText={"Remove"}
        cancelText={"Cancel"}
        width={"608px"}
        //   footer={false}
      >
        <h1>Remove this task</h1>
        <p>Are you sure you want to remove this task? </p>
      </Modal>
    </div>
  );
};
const EditWorkout = ({ setWorkoutDetail, setEditWorkout, selectedWorkout }) => {
  return (
    <EditWorkoutContextProvider
      selectedWorkout={selectedWorkout}
      setEditWorkout={setEditWorkout}
      setWorkoutDetail={setWorkoutDetail}
    >
      <EditWorkoutPage
        setWorkoutDetail={setWorkoutDetail}
        setEditWorkout={setEditWorkout}
      />
    </EditWorkoutContextProvider>
  );
};
export default EditWorkout;
