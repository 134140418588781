import React, { useContext, useEffect, useState } from "react";
import { Table, Tabs, Input, Button, Tooltip, message, FloatButton } from "antd";
import "./group-home.scss";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import { getDate, getDateWithoutDay } from "../../../util/config";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../coach-context/group-context/group-context";
import { UserContext } from "../../../context/user-context";
const GroupHomePage = () => {
  const {selectedSport} = useContext(UserContext)
  const {
    pageNumber,
    pageSize,
    groups,
    totalGroups,
    handleTableChange,
    loading,
    setPageNumber,
    setGroups,
    setSearch,
  } = useContext(GroupContext);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  var indents = [];
  for (var i = 0; i <= totalGroups; i++) {
    indents.push(
      <span className="indent" key={i}>
        {i}
      </span>
    );
  }
  const columns = [
    {
      title: "GROUP NAME",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (record, index) => {
        return (
          <Tooltip placement="bottom" title={record}>
            {record}
          </Tooltip>
        );
      },
    },
    {
      title: "Sport",
      dataIndex: "sport",
      key: "sport",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.sport === null ? "N/A" : record.sport}
          </div>
        );
      },
    },
    {
      title: "Seats",
      dataIndex: "seats",
      key: "seats",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.seats === 100000 ? "N/A" : record.seats}
          </div>
        );
      },
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.group_expiry === false ?
              <Button className="green-tag" key={index}>
                Active
              </Button> :
              <Button className="pink-tag">
                Subscription Expired
              </Button>

            }
          </div>
        );
      },
    },
    {
      title: "VALID TILL",
      dataIndex: "expiry_date",
      key: "expiry_date",
      width: 200,
      render: (text, record, index) => {
        return (
          <div key={index}>
            {getDateWithoutDay(record.expiry_date)}
          </div>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoGroupDetail = (record) => {
    navigate({
      pathname: `./detail`,
      search: `?name=${record.name}&id=${record.id}`,
    });
    // }
  }
  const searchGroup = (e) => {
    if (e.target.value.length >= 3) {
      setPageNumber(1);
      setGroups([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setGroups((groups) => []);
      setSearch("");
    }
  };
  return (
    <div className="group-home-main">
      <div style={{ position: "absolute", right: 0, top: "8px" }}>
      </div>
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchGroup}
          />
          <div className="right-div">
            <h4>Total Groups: </h4>
            <h4 style={{ fontWeight: "700" }}> {totalGroups}</h4>
          </div>
        </div>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => gotoGroupDetail(record),
            };
          }}
          loading={loading}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: totalGroups,
          }}
          columns={columns}
          dataSource={groups}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
    </div>
  );
};

const GroupHome = () => {
  return (
    <GroupContextProvider>
      <GroupHomePage />
    </GroupContextProvider>
  );
};
export default GroupHome;
