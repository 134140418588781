import React, { useContext, useEffect } from "react";
import { Card, Col, Empty, Row } from "antd";
import Play from "../../../assets/images/play.svg";
import Thumbnail from "../../../assets/images/chatLogo.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { VisionShootingContext } from "../../../context/group-context/vision-shooting-context";
import { getDateWithoutDay } from "../../../util/config";
import ShootingDetailModal from "./shooting-detail-modal";
import "./vision-shooting-sessions.scss";
const style = {
  padding: "5px 0",
};
const IndividualShootingSession = () => {
  const {
    sessionsData,
    setSelectedShooting,
    setShootingDetailModal,
    getShootingDetail,
    shootingDetailModal,
    getProfileSessions,
    hasMore,
  } = useContext(VisionShootingContext);
  useEffect(()=>{
    getProfileSessions()
  },[])
  return (
    <div>
      <Row
      style={{ display: "flex" }}
      className="groups-row submission-video"
      gutter={[34, 34]}
      >
        <InfiniteScroll
          className="sessions-infinite-scroll"
          dataLength={sessionsData.length}
          next={getProfileSessions}
          width={400}
          hasMore={hasMore}
          loader={<h4 className="scroll-loading">Loading...</h4>}
        >
          {sessionsData?.length > 0 ? (
            sessionsData.map((item, index) => {
              return (
                <Col
                  className="gutter-row"
                  span={8}
                  style={{ padding: "17px 17px 16px" }}
                  key={index}
                >
                  <div style={style} className="div-1">
                    <Card
                      hoverable
                      className="video-card"
                      style={{
                        cursor: item.media_url !== null ? "pointer" : "default",
                      }}
                      onClick={
                        item.media_url === null
                          ? null
                          : () => {
                              setSelectedShooting(item);
                              setShootingDetailModal(true);
                              getShootingDetail(item.id);
                            }
                      }
                      cover={
                        <>
                          <div id="video-container">
                            {item?.media_thumbnail ? (
                              <div
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                }}
                              >
                                <img
                                  src={item.media_thumbnail}
                                  style={{ width: "100%", height: "164px" }}
                                />
                                <img
                                  src={Play}
                                  style={{ width: 35, position: "absolute" }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={Thumbnail}
                                  style={{ width: "40%", marginTop: "12%" }}
                                ></img>
                              </div>
                            )}
                          </div>
                        </>
                      }
                    >
                      <div className="date">
                        Submitted:{" "}
                        <span style={{ fontWeight: "bold", color: "#181c32" }}>
                          {getDateWithoutDay(item.created_at)}
                        </span>
                      </div>
                      <div className="date">
                        Shots:{" "}
                        <span style={{ fontWeight: "bold", color: "#181c32" }}>
                          {item.total_make}/{item.total_attempts}
                        </span>
                      </div>
                    </Card>
                  </div>
                </Col>
              );
            })
          ) : (
            hasMore === false &&
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ margin: "70px auto" }}
            />
          )}
        </InfiniteScroll>
      </Row>
      {shootingDetailModal === true && <ShootingDetailModal />}
    </div>
  );
};

export default IndividualShootingSession;
