import React, { useContext, useEffect, useState } from "react";
import "./vision-shooting-sessions.scss";
import Search from "../../../assets/images/search.svg";
import { Col, Divider, Input, Select, Skeleton, Row, Table, Tabs } from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import { UserContext } from "../../../context/user-context";
import Avatar from "react-avatar";
import ShootingDetail from "./shooting-detail";
import { VisionShootingContext, VisionShootingContextProvider } from "../../../context/group-context/vision-shooting-context";
import TeamShootingSession from "./team-shooting-sessions";

const VisionShootingSessionsPage = () => {
  const { selectedSport , selectedNotification } = useContext(UserContext);
  const {visionLeaderboards , setLeaderboardProfile,activeTab,loading,shootingDetailView , setShootingDetailView , setVisionLeaderboards , setActiveTab,getVisionLeaderBoard , setSearch , search , sort , setSort , params , getShootingDetail , setShootingDetailModal} = useContext(VisionShootingContext)
  const searchShooting = (e) => {
    if (e.target.value.length >= 3) {
      setVisionLeaderboards([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setVisionLeaderboards((visionLeaderboards) => []);
      setSearch("");
    }
  };
  const [imageLoaded, setImageLoaded] = useState("");
  useEffect(()=>{
    if(activeTab === "individual"){
    getVisionLeaderBoard()
    }
  },[sort,search , activeTab])
  const columns = [
    {
      title: "Member",
      dataIndex: "member",
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          {record?.avatar !== null && imageLoaded === "" && (
            <Skeleton.Image active={true} className="user-skeleton" />
          )}
          {!record?.avatar ? (
            <Avatar
              name={record?.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          ) : (
            <img
              src={record?.avatar}
              style={{
                width:40,height:40,display:imageLoaded === "" ? "none" : "block",borderRadius:6
              }}
              onLoad={() => setImageLoaded("loaded")}
            ></img>
          )}
          <div className="name-div">
            <h3 className="user-name">
              {record?.name}
            </h3>
            <h4 className="user-handle">@{record?.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Shots",
      dataIndex: "completion_percentage",
      render: (text, record) => <span>{record.total_attempts} </span>,
    },
    {
      title: "",
      dataIndex: "",
      render: (text, record) => (
        record.recent_submission === true &&
        <span className="new-sub" style={{ display: "block" }}></span>
      ),
    },
  ];
  useEffect(()=>{
      if(params.tab === "team_shooting_sessions"){
        setActiveTab("teams")
        setShootingDetailModal(true);
        getShootingDetail(params.session_id);
      }
  },[selectedNotification])
  const items = [
    {
      key: "individual",
      label: `Vision member shooting sessions`,
      children:  
      shootingDetailView === false? 
      <>
      <div style={{ display: "flex" , marginTop:20 }}>
      <Input
        addonBefore={<img src={Search} />}
        placeholder="Search"
        className="search-input"
        onChange={searchShooting}
        style={{ width: 300 }}
      />
      <div style={{ marginLeft: "auto" }}>
        <span className="sorting">Sorting: </span>
        <Select
          placeholder="Newest"
            onChange={(e) => setSort(e)}
            value={sort}
          style={{ marginLeft: "auto" }}
          suffixIcon={<img src={DownArrow} />}
          className="select-input"
          popupClassName={
            "custom-action-select-dropdown " +
            (selectedSport === 1
              ? "orange-theme-select"
              : "blue-theme-select")
          }
          options={[
            {
              value: "desc",
              label: "Newest",
            },
            {
              value: "asc",
              label: "Oldest",
            },
          ]}
        />
      </div>
    </div>
    <div className="sessions-main">
      <div style={{ width: "100%" }}>
        <Table
          rowKey={(obj) => obj.id}
          columns={columns}
          pagination={false}
          onRow={(record)=>{return{onClick:()=> {setShootingDetailView(true);setLeaderboardProfile(record)}}}}
          dataSource={visionLeaderboards}
          className="tasks-table"
          loading={loading}
        />
      </div>
    </div>
    </> : 
      <ShootingDetail/>
      },
    {
      key: "teams",
      label: `Vision team shooting sessions`,
      children: <TeamShootingSession />,
    },
  ];
  const onChange = (key) => {
    setActiveTab(key);
  };
  return (
    <div className="vision-shooting-main">
        
      <div style={{ display: "flex" }}>
        <h1>Vision Shooting Sessions</h1>
      </div>
      <Tabs
        defaultActiveKey={params.tab && params.tab}
        items={items}
        onChange={onChange}
        activeKey={activeTab}
        destroyInactiveTabPane
        className={
          "shooting-detail-tabs " +
          (selectedSport === 1 ? "orange-theme" : "blue-theme")
        }
      />

    </div>
  );
};

const VisionShootingSessions = () => {
    return(
        <VisionShootingContextProvider>
            <VisionShootingSessionsPage />
        </VisionShootingContextProvider>
    )
}


export default VisionShootingSessions;
