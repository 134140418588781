import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, DatePicker, Form, InputNumber, Select } from "antd";
import dayjs from "dayjs";
import "./combine-measurements-modal.scss";
import Calendar from "../../../../assets/images/calendar.svg";
import { CombineMeasurementContext } from "../../../../context/user-context/combine-measurement-context";
import { UserContext } from "../../../../context/user-context";
const EditCombineMeasurementModal = ({ setEditModal, editModal }) => {
  const {
    updateCombineMeasurements,
    getSpecificProfileCm,
    singleProfileData,
    measurementDate,
  } = useContext(CombineMeasurementContext);
  const { selectedSport, darkButtonBackground, color, lightButtonBackground } =
    useContext(UserContext);
  const dateFormat = "MMMM D, YYYY";
  const [form] = Form.useForm();
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  useEffect(() => {
    if (editModal === false) {
      form.resetFields();
    }
  }, [editModal]);
  const handleCancel = () => {
    setEditModal(false);
  };
  useEffect(() => {
    getSpecificProfileCm();
  }, []);
  useEffect(() => {
    singleProfileData.map((item, index) => {
      return (
        <>
          {form.setFieldValue("date", dayjs(measurementDate))}
          {item.measurement.code === "HEIGHT"
            ? form.setFieldsValue({
                height_feet: item.ft,
                height_inch: item.inch,
                height_dec:
                // item.decimal !== 0 ? item.decimal :
                 item.decimal
              })
            : item.measurement.code === "WEIGHT"
            ? form.setFieldsValue({
                weight_lbs: item.value,
              })
            : item.measurement.code === "WINGSPAN"
            ? form.setFieldsValue({
                wingspan_feet: item.ft,
                wingspan_inch: item.inch,
                wingspan_dec:
                // item.decimal !== 0 ? item.decimal :
                 item.decimal
              })
            : item.measurement.code === "MAX_VERTICAL"
            ? form.setFieldsValue({
                maxVertical_inch: item.inch,
                maxVertical_dec:
                  // item.decimal !== 0
                  //   ? parseInt(item.decimal * 100)
                  //   : 
                    item.decimal,
              })
            : item.measurement.code === "STANDING_VERTICAL"
            ? form.setFieldsValue({
                standingVertical_inch: item.inch,
                standingVertical_dec:
                  // item.decimal !== 0
                  //   ? parseInt(item.decimal * 100)
                  //   :
                   item.decimal,
              })
            : item.measurement.code === "COURT_SPRINT"
            ? form.setFieldsValue({
                court_sec: item.sec,
                court_dec:
                  item.decimal !== 0
                    ? parseInt(item.decimal * 100)
                    :
                     item.decimal,
              })
            : item.measurement.code === "LANE_AGILITY"
            ? form.setFieldsValue({
                lane_sec: item.sec,
                lane_dec:
                  item.decimal !== 0
                    ? parseInt(item.decimal * 100)
                    : 
                    item.decimal,
              })
            : item.measurement.code === "STANDING_REACH"
            ? form.setFieldsValue({
                standing_reach_feet: item.ft,
                standing_reach_inch: item.inch,
                standing_reach_dec:
                // item.decimal !== 0 ? item.decimal :
                 item.decimal
              })
            : ""}
        </>
      );
    });
  }, [measurementDate, singleProfileData]);
  return (
    <Modal
      title="Basic Modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      className="combine-measurement-modal"
      width={624}
      open={editModal}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="header">
        <h1>Combine Measurements</h1>
        <span>Update combine measurements</span>
      </div>
      <div className="form">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={updateCombineMeasurements}
        >
          <Form.Item
            name="date"
            label="Select Date"
            rules={[
              {
                required: true,
                message: "Please Select date.",
              },
            ]}
          >
            <DatePicker
              className={
                "date-picker " +
                (selectedSport === 1 ? "orange-picker" : "blue-picker")
              }
              rootClassName={
                selectedSport === 1 ? "orange-picker" : "blue-picker"
              }
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              style={{ height: 38 }}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <h4>Measurements</h4>

          <Form.Item
            label="Height (w/o) Shoes"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="height_feet"
              rules={[
                {
                  type: "number",
                  min: 2,
                  max: 7,
                  pattern: new RegExp("^[0-9]*$"),
                  message: "Feet must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter height",
                },
              ]}
            >
              <InputNumber
                addonBefore={"ft"}
                placeholder="Feet"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="height_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 11,
                  message: "Inches must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="height_dec"
              // rules={[
              //   {
              //     type: "number",
              //     min: 0,
              //     max: 75,
              //     message: "Decimal must be between ${min} to ${max}.",
              //   },
              // ]}
            >
              <Select
                placeholder="Decimal"
                style={{ marginLeft: "auto", height: 40, width: 168 }}
                suffixIcon={<div>dec</div>}
                removeIcon={true}
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                className="select-input"
                options={[
                  {
                    value: 0,
                    label: "0",
                  },
                  {
                    value: 25,
                    label: "0.25",
                  },
                  {
                    value: 5,
                    label: "0.5",
                  },
                  {
                    value: 75,
                    label: "0.75",
                  },
                ]}
              />
              {/* <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              /> */}
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Weight"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="weight_lbs"
              rules={[
                {
                  type: "number",
                  min: 10,
                  max: 299,
                  message: "Weight must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter weight",
                },
              ]}
              style={{ width: "100%" }}
            >
              <InputNumber
                addonBefore={"lbs"}
                placeholder="Lbs"
                className="weight-input"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Wingspan"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="wingspan_feet"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 8,
                  message: "Feet must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter wingspan",
                },
              ]}
            >
              <InputNumber
                addonBefore={"ft"}
                placeholder="Feet"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="wingspan_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 11,
                  message: "Inches must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="wingspan_dec"
              // rules={[
              //   {
              //     type: "number",
              //     min: 0,
              //     max: 75,
              //     message: "Decimal must be between ${min} to ${max}.",
              //   },
              // ]}
            >
              <Select
                placeholder="Decimal"
                style={{ marginLeft: "auto", height: 40, width: 168 }}
                suffixIcon={<div>dec</div>}
                removeIcon={true}
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                className="select-input"
                options={[
                  {
                    value: 0,
                    label: "0",
                  },
                  {
                    value: 25,
                    label: "0.25",
                  },
                  {
                    value: 5,
                    label: "0.5",
                  },
                  {
                    value: 75,
                    label: "0.75",
                  },
                ]}
              />
              {/* <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              /> */}
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Max. Vertical"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="maxVertical_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 55,
                  message: "Inches must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter max. vertical",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="maxVertical_dec"
              // rules={[
              //   {
              //     type: "number",
              //     min: 0,
              //     max: 75,
              //     message: "Decimal must be between ${min} to ${max}.",
              //   },
              // ]}
            >
              <Select
                placeholder="Decimal"
                style={{ marginLeft: "auto", height: 40, width: 255 }}
                suffixIcon={<div>dec</div>}
                removeIcon={true}
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                className="select-input"
                options={[
                  {
                    value: 0,
                    label: "0",
                  },
                  {
                    value: 5,
                    label: "0.5",
                  },
                ]}
              />
              {/* <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              /> */}
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Standing Vertical"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="standingVertical_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 55,
                  message: "Inches must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter standing vertical",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="standingVertical_dec"
              // rules={[
              //   {
              //     type: "number",
              //     min: 0,
              //     max: 75,
              //     message: "Decimal must be between ${min} to ${max}.",
              //   },
              // ]}
            >
              <Select
                placeholder="Decimal"
                style={{ marginLeft: "auto", height: 40, width: 255 }}
                suffixIcon={<div>dec</div>}
                removeIcon={true}
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                className="select-input"
                options={[
                  {
                    value: 0,
                    label: "0",
                  },
                  {
                    value: 5,
                    label: "0.5",
                  },
                ]}
              />
              {/* <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              /> */}
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="3/4 Court Sprint"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="court_sec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 1000,
                  message: "Seconds must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter 3/4 court sprint",
                },
              ]}
            >
              <InputNumber
                addonBefore={"s"}
                placeholder="Sec"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="court_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 1000,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                prefix="0."
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Lane Agility"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="lane_sec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 1000,
                  message: "Seconds must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter lane agility",
                },
              ]}
            >
              <InputNumber
                addonBefore={"s"}
                placeholder="Sec"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="lane_dec"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 1000,
                  message: "Decimal must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                prefix="0."
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Standing Reach"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="standing_reach_feet"
              rules={[
                {
                  type: "number",
                  min: 2,
                  max: 7,
                  pattern: new RegExp("^[0-9]*$"),
                  message: "Feet must be between ${min} to ${max}.",
                },
                {
                  required: true,
                  message: "Please enter standing reach",
                },
              ]}
            >
              <InputNumber
                addonBefore={"ft"}
                placeholder="Feet"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="standing_reach_inch"
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 11,
                  message: "Inches must be between ${min} to ${max}.",
                },
              ]}
            >
              <InputNumber
                addonBefore={"in"}
                placeholder="Inch"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              />
            </Form.Item>
            <Form.Item
              name="standing_reach_dec"
              // rules={[
              //   {
              //     type: "number",
              //     min: 0,
              //     max: 75,
              //     message: "Decimal must be between ${min} to ${max}.",
              //   },
              // ]}
            >
              <Select
                placeholder="Decimal"
                style={{ marginLeft: "auto", height: 40, width: 168 }}
                suffixIcon={<div>dec</div>}
                removeIcon={true}
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                className="select-input"
                options={[
                  {
                    value: 0,
                    label: "0",
                  },
                  {
                    value: 25,
                    label: "0.25",
                  },
                  {
                    value: 5,
                    label: "0.5",
                  },
                  {
                    value: 75,
                    label: "0.75",
                  },
                ]}
              />
              {/* <InputNumber
                addonBefore={"dec"}
                placeholder="Decimal"
                type="number"
                onKeyDown={(e) => e.key === "." && e.preventDefault()}
              /> */}
            </Form.Item>
          </Form.Item>
          <Form.Item
            style={{ display: "flex", justifyContent: "end", marginTop: 30 }}
          >
            <Button
              type="default"
              onClick={() => setEditModal(false)}
              style={{ color: color, background: lightButtonBackground }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: darkButtonBackground }}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default EditCombineMeasurementModal;
