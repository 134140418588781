import React, { useEffect, useState } from "react";
import {
  getDataTrainingCoach,
  jsonPostDataTraining,
  getDataCoach,
} from "../../util/coach-api-calls";
import { message } from "antd";
export const EditWorkoutContext = React.createContext({});
export const EditWorkoutContextProvider = ({ children = {}  , selectedWorkout,setEditWorkout,setWorkoutDetail}) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [sort, setSort] = useState(true);
  const [title, setTitle] = useState("");
  const [newTaskArray, setNewTaskArray] = useState([]);
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState();
  const [privacy, setPrivacy] = useState(false);
  const [existingTaskModal, setExistingTaskModal] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workoutsData, setWorkoutsData] = useState([]);
  const [workoutDetail, setWorkoutDetails] = useState({});
  const [generalDetail, setGeneralDetail] = useState([]);

  const getGroupDetail = (loading) => {
    if(loading !== false){
    setLoading(true);
  }
    getDataCoach(`group/${params.id}`)
      .then((res) => {
        setGeneralDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWorkoutDetail = () =>{
    getDataTrainingCoach(`coach/view/workout/${selectedWorkout.id}`)
    .then((res)=>{
        setWorkoutDetails(res.data)
    }).catch((err)=>{
        console.log(err)
    })
  }
  useEffect(()=>{
    getGroupDetail();
  },[])
  const updateExistingWorkout = (update_template , curriculum_group) => {
    var x = newTaskArray.map(function (item , index) {
      let tags =
        typeof item.tags === "object" ? item.tags.map((a) => a.tag) : item.tags;
      return {
        lock: current === index || index > current || item.lock === true && index !== 0 ? true :  false,
        id: item.id,
        title: item.title,
        is_public: item.is_public,
        type: item.type,
        order: item.order,
        // lock : item.lock,
        media_url: item.media_url,
        media_uri: item.media_uri,
        media_thumbnail: item.media_thumbnail,
        image: item.image,
        media_type: item.media_type,
        media_required: item.media_required,
        instruction: item.instruction,
        best_score: item.best_score,
        skill_level: item.skill_level,
        is_time_limit: item.is_time_limit,
        time_limit_seconds: item.time_limit_seconds,
        score_based_test: item.score_based_test,
        num_completion: isNaN(item.num_completion) ? 1 : item.num_completion,
        legacy_point: item.legacy_point,
        allow_more_reps: item.allow_more_reps,
        group_id: params.id ,
        group_name: workoutDetail.group_name,
        // tags: typeof tags === "object" ? tags.toString().toLowerCase() : tags.toLowerCase(),
        status: item.status,
        taskgroup_id: item.taskgroup_id,
        library_taskgroup_id: item.library_taskgroup_id,
        task_id: item.task_id,
        is_cw : curriculum_group
      };
    });
    let data = {
      id: workoutDetail.id,
      title:title,
      type: workoutDetail.type,
      is_public: privacy,
      group_id:workoutDetail.group_id ,
      group_name: workoutDetail.group_name,
      // taskgroup_id: workoutTasks.taskgroup_id,
      create_existing: false,
      update_template: true,
      list_tasks: x,
      is_cw:curriculum_group
    };
    jsonPostDataTraining(`existing/workout`, data)
      .then((res) => {
        message.success({
          content:"Workout updated successfully"
        })
        setEditWorkout(false)
        setWorkoutDetail(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultContext = {
    workoutsData,
    existingTaskModal,
    setExistingTaskModal,
    loading,
    selectedWorkout,
    setNewTaskModal,
    setNewTaskArray,
    current,
    newTaskArray,
    newTaskModal,
    error,
    setTitle,
    setPrivacy,
    params,
    setCurrent,
    setError,
    title,
    sort , 
    setSort,
    setWorkoutsData,
    generalDetail,
    updateExistingWorkout,
    getWorkoutDetail,
    workoutDetail
  };
  return (
    <EditWorkoutContext.Provider value={defaultContext}>
      {children}
    </EditWorkoutContext.Provider>
  );
};
