import React, { useState, useEffect, useContext } from "react";
import { Button } from "antd";
import { UserContext } from "../../context/user-context";

let bg1 = "#109500",
  bg2 = "#5DC800",
  bg3 = "#BDE407",
  bg4 = "#F8D62B",
  bg5 = "#FC8D01",
  bg6 = "#F54F0F",
  bg7 = "#D0021B";
const Chart = ({ shotChart, setLoading }) => {
  const { color, lightButtonBackground } = useContext(UserContext);
  const [zoneTotal, setZoneTotal] = useState(0);
  const [testTotal, setTestTotal] = useState(0);
  const [selectedZoneTotal, setSelectedZoneTotal] = useState(0);
  const [selectedTestTotal, setSelectedTestTotal] = useState(0);
  const [zoneAbg, setZoneAbg] = useState();
  const [zoneBbg, setZoneBbg] = useState();
  const [zoneCbg, setZoneCbg] = useState();
  const [zoneDbg, setZoneDbg] = useState();
  const [zoneEbg, setZoneEbg] = useState();
  const [zoneFbg, setZoneFbg] = useState();
  const [zoneGbg, setZoneGbg] = useState();
  const [zoneHbg, setZoneHbg] = useState();
  const [zoneIbg, setZoneIbg] = useState();
  const [zoneJbg, setZoneJbg] = useState();
  const [zoneKbg, setZoneKbg] = useState();
  const [zoneLbg, setZoneLbg] = useState();
  const [zoneMbg, setZoneMbg] = useState();
  const [zoneNbg, setZoneNbg] = useState();
  const [zoneObg, setZoneObg] = useState();
  const [zonePbg, setZonePbg] = useState();
  const [selectedZone, setSelectedZone] = useState([
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ]);
  const [selectedAll, setSelectedAll] = useState(true);
  const selectAllZone = () => {
    setSelectedZone([
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
    ]);
    // setSelectedAll(true)
    setSelectedZoneTotal(
      Object.values(shotChart?.total_zone).reduce((a, b) => a + b, 0)
    );
    setSelectedTestTotal(
      Object.values(shotChart?.test_zone).reduce((a, b) => a + b, 0)
    );
    setZoneTotal(
      Object.values(shotChart?.total_zone).reduce((a, b) => a + b, 0)
    );
    setTestTotal(
      Object.values(shotChart?.test_zone).reduce((a, b) => a + b, 0)
    );
  };
  useEffect(() => {
    if (shotChart && Object.keys(shotChart).length > 0) {
      setZoneTotal(
        Object.values(shotChart?.total_zone).reduce((a, b) => a + b, 0)
      );
      setTestTotal(
        Object.values(shotChart?.test_zone).reduce((a, b) => a + b, 0)
      );
      setSelectedZoneTotal(
        Object.values(shotChart?.total_zone).reduce((a, b) => a + b, 0)
      );
      setSelectedTestTotal(
        Object.values(shotChart?.test_zone).reduce((a, b) => a + b, 0)
      );
      setLoading(false);
    } else if (shotChart === null) {
      setLoading(false);
    }
    if (Object.keys(shotChart).length > 0) {
      setZoneAbg(
        (shotChart?.test_zone["A"] / shotChart?.total_zone["A"]) * 100
      );
      setZoneBbg(
        (shotChart?.test_zone["B"] / shotChart?.total_zone["B"]) * 100
      );
      setZoneCbg(
        (shotChart?.test_zone["C"] / shotChart?.total_zone["C"]) * 100
      );
      setZoneDbg(
        (shotChart?.test_zone["D"] / shotChart?.total_zone["D"]) * 100
      );
      setZoneEbg(
        (shotChart?.test_zone["E"] / shotChart?.total_zone["E"]) * 100
      );
      setZoneFbg(
        (shotChart?.test_zone["F"] / shotChart?.total_zone["F"]) * 100
      );
      setZoneGbg(
        (shotChart?.test_zone["G"] / shotChart?.total_zone["G"]) * 100
      );
      setZoneHbg(
        (shotChart?.test_zone["H"] / shotChart?.total_zone["H"]) * 100
      );
      setZoneIbg(
        (shotChart?.test_zone["I"] / shotChart?.total_zone["I"]) * 100
      );
      setZoneJbg(
        (shotChart?.test_zone["J"] / shotChart?.total_zone["J"]) * 100
      );
      setZoneKbg(
        (shotChart?.test_zone["K"] / shotChart?.total_zone["K"]) * 100
      );
      setZoneLbg(
        (shotChart?.test_zone["L"] / shotChart?.total_zone["L"]) * 100
      );
      setZoneMbg(
        (shotChart?.test_zone["M"] / shotChart?.total_zone["M"]) * 100
      );
      setZoneNbg(
        (shotChart?.test_zone["N"] / shotChart?.total_zone["N"]) * 100
      );      
      setZoneObg(
        (shotChart?.test_zone["O"] / shotChart?.total_zone["O"]) * 100
      );
      setZonePbg(
        (shotChart?.test_zone["P"] / shotChart?.total_zone["P"]) * 100
      );
    }
  }, [shotChart]);
  const clearZone = () => {
    setSelectedAll(false);
    setSelectedZone([]);
    setTestTotal(0);
    setZoneTotal(0);
    setSelectedTestTotal(0);
    setSelectedZoneTotal(0);
  };
  const selectZone = (e) => {
    if (e.target.id !== "") {
      if (
        shotChart?.test_zone[e.target.id] !== 0 ||
        shotChart?.total_zone[e.target.id] !== 0
      ) {
        setSelectedAll(false);
        // let blockArea = ["K", "L", "N", "O"];
        // if (!blockArea.includes(e.target.id)) {
          if (!selectedZone.includes(e.target.id) && selectedAll === false) {
            setSelectedZoneTotal(
              selectedZoneTotal + shotChart?.total_zone[e.target.id]
            );
            setSelectedTestTotal(
              selectedTestTotal + shotChart?.test_zone[e.target.id]
            );
            setSelectedZone((selectedZone) => [...selectedZone, e.target.id]);
          } else {
            setSelectedZoneTotal(
              selectedZoneTotal - shotChart?.total_zone[e.target.id]
            );
            setSelectedTestTotal(
              selectedTestTotal - shotChart?.test_zone[e.target.id]
            );
            setSelectedAll(false);
            const updatedArray = selectedZone.filter(
              (item) => item !== e.target.id
            );
            setSelectedZone(updatedArray);
          }
        // }
      }
    }
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <svg
          style={{ padding: 20 , cursor:"pointer" }}
          width="588"
          height="367"
          className="chart"
          viewBox="0 0 588 367"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={selectZone}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M209.681 284.412L139.734 267.834C135.314 282.437 132.936 297.916 132.936 313.947V366.046H205.529V311.229C205.529 301.881 206.986 292.869 209.681 284.412Z"
            fill={
              selectedZone.includes("K") || selectedAll
                ? zoneKbg >= 70
                  ? bg1
                  : zoneKbg >= 50 && zoneKbg <= 69
                  ? bg2
                  : zoneKbg >= 40 && zoneKbg <= 49
                  ? bg3
                  : zoneKbg >= 30 && zoneKbg <= 39
                  ? bg4
                  : zoneKbg >= 20 && zoneKbg <= 29
                  ? bg5
                  : zoneKbg >= 10 && zoneKbg <= 19
                  ? bg6
                  : zoneKbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="K"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M258.612 327.992H329.39V325.274H258.612V327.992ZM283.112 312.589C283.112 306.586 287.989 301.716 294.001 301.716C300.015 301.716 304.89 306.586 304.89 312.589C304.89 318.593 300.015 323.462 294.001 323.462C287.989 323.462 283.112 318.593 283.112 312.589ZM375.662 285.03L375.575 285.052L375.334 284.036C367.806 261.582 351.217 243.276 329.898 233.45L327.835 232.55L327.84 232.535C317.459 228.077 306.021 225.607 294.001 225.607C255.952 225.607 223.69 250.354 212.478 284.608L212.373 285.052L212.336 285.043C209.685 293.297 208.251 302.097 208.251 311.23V366.047H379.751V311.23C379.751 302.091 378.315 293.288 375.662 285.03Z"
            fill={
              selectedZone.includes("P") || selectedAll
                ? zonePbg >= 70
                  ? bg1
                  : zonePbg >= 50 && zonePbg <= 69
                  ? bg2
                  : zonePbg >= 40 && zonePbg <= 49
                  ? bg3
                  : zonePbg >= 30 && zonePbg <= 39
                  ? bg4
                  : zonePbg >= 20 && zonePbg <= 29
                  ? bg5
                  : zonePbg >= 10 && zonePbg <= 19
                  ? bg6
                  : zonePbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="P"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M293.999 320.748C298.509 320.748 302.166 317.097 302.166 312.594C302.166 308.091 298.509 304.439 293.999 304.439C289.489 304.439 285.833 308.091 285.833 312.594C285.833 317.097 289.489 320.748 293.999 320.748Z"
            fill={
              selectedZone.includes("P") || selectedAll
                ? zonePbg >= 70
                  ? bg1
                  : zonePbg >= 50 && zonePbg <= 69
                  ? bg2
                  : zonePbg >= 40 && zonePbg <= 49
                  ? bg3
                  : zonePbg >= 30 && zonePbg <= 39
                  ? bg4
                  : zonePbg >= 20 && zonePbg <= 29
                  ? bg5
                  : zonePbg >= 10 && zonePbg <= 19
                  ? bg6
                  : zonePbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="P"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M448.266 267.823L378.319 284.397C381.016 292.857 382.473 301.871 382.473 311.225V366.042H455.066V313.943C455.066 297.91 452.688 282.427 448.266 267.823Z"
            fill={
              selectedZone.includes("O") || selectedAll
                ? zoneObg >= 70
                  ? bg1
                  : zoneObg >= 50 && zoneObg <= 69
                  ? bg2
                  : zoneObg >= 40 && zoneObg <= 49
                  ? bg3
                  : zoneObg >= 30 && zoneObg <= 39
                  ? bg4
                  : zoneObg >= 20 && zoneObg <= 29
                  ? bg5
                  : zoneObg >= 10 && zoneObg <= 19
                  ? bg6
                  : zoneObg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id={"O"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M529.401 248.604C538.266 274.044 543.083 301.365 543.083 329.806V366.049H580.806C584.779 366.049 587.999 362.833 587.999 358.866V234.719L529.401 248.604Z"
            fill={
              selectedZone.includes("E") || selectedAll
                ? zoneEbg >= 70
                  ? bg1
                  : zoneEbg >= 50 && zoneEbg <= 69
                  ? bg2
                  : zoneEbg >= 40 && zoneEbg <= 49
                  ? bg3
                  : zoneEbg >= 30 && zoneEbg <= 39
                  ? bg4
                  : zoneEbg >= 20 && zoneEbg <= 29
                  ? bg5
                  : zoneEbg >= 10 && zoneEbg <= 19
                  ? bg6
                  : zoneEbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id={"E"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.000305176 234.718V358.865C0.000305176 362.832 3.22057 366.048 7.19297 366.048H44.9169V329.806C44.9169 301.365 49.7334 274.045 58.5969 248.607L0.000305176 234.718Z"
            fill={
              selectedZone.includes("A") || selectedAll
                ? zoneAbg >= 70
                  ? bg1
                  : zoneAbg >= 50 && zoneAbg <= 69
                  ? bg2
                  : zoneAbg >= 40 && zoneAbg <= 49
                  ? bg3
                  : zoneAbg >= 30 && zoneAbg <= 39
                  ? bg4
                  : zoneAbg >= 20 && zoneAbg <= 29
                  ? bg5
                  : zoneAbg >= 10 && zoneAbg <= 19
                  ? bg6
                  : zoneAbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id={"A"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M388.595 100.495C453.506 127.026 504.667 180.041 528.488 246.026L588 231.924V7.18311C588 3.21599 584.779 0 580.807 0H432.574L388.595 100.495Z"
            fill={
              selectedZone.includes("D") || selectedAll
                ? zoneDbg >= 70
                  ? bg1
                  : zoneDbg >= 50 && zoneDbg <= 69
                  ? bg2
                  : zoneDbg >= 40 && zoneDbg <= 49
                  ? bg3
                  : zoneDbg >= 30 && zoneDbg <= 39
                  ? bg4
                  : zoneDbg >= 20 && zoneDbg <= 29
                  ? bg5
                  : zoneDbg >= 10 && zoneDbg <= 19
                  ? bg6
                  : zoneDbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M360.286 165.184C402.836 183.907 435.857 220.098 450.11 264.599L525.82 246.66C502.208 181.515 451.638 129.183 387.504 102.988L360.286 165.184Z"
            fill={
              selectedZone.includes("I") || selectedAll
                ? zoneIbg >= 70
                  ? bg1
                  : zoneIbg >= 50 && zoneIbg <= 69
                  ? bg2
                  : zoneIbg >= 40 && zoneIbg <= 49
                  ? bg3
                  : zoneIbg >= 30 && zoneIbg <= 39
                  ? bg4
                  : zoneIbg >= 20 && zoneIbg <= 29
                  ? bg5
                  : zoneIbg >= 10 && zoneIbg <= 19
                  ? bg6
                  : zoneIbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="I"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230.183 164.126C249.795 155.879 271.361 151.318 294 151.318C316.628 151.318 338.182 155.874 357.786 164.112L384.977 101.978C356.831 90.8444 326.133 84.7231 294 84.7231C261.858 84.7231 231.154 90.848 203.002 101.985L230.183 164.126Z"
            fill={
              selectedZone.includes("H") || selectedAll
                ? zoneHbg >= 70
                  ? bg1
                  : zoneHbg >= 50 && zoneHbg <= 69
                  ? bg2
                  : zoneHbg >= 40 && zoneHbg <= 49
                  ? bg3
                  : zoneHbg >= 30 && zoneHbg <= 39
                  ? bg4
                  : zoneHbg >= 20 && zoneHbg <= 29
                  ? bg5
                  : zoneHbg >= 10 && zoneHbg <= 19
                  ? bg6
                  : zoneHbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="H"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M331.416 231.159C352.798 241.137 369.525 259.403 377.45 281.817L447.453 265.231C433.403 221.568 400.974 186.059 359.197 167.677L331.416 231.159Z"
            fill={
              selectedZone.includes("N") || selectedAll
                ? zoneNbg >= 70
                  ? bg1
                  : zoneNbg >= 50 && zoneNbg <= 69
                  ? bg2
                  : zoneNbg >= 40 && zoneNbg <= 49
                  ? bg3
                  : zoneNbg >= 30 && zoneNbg <= 39
                  ? bg4
                  : zoneNbg >= 20 && zoneNbg <= 29
                  ? bg5
                  : zoneNbg >= 10 && zoneNbg <= 19
                  ? bg6
                  : zoneNbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="N"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M227.683 165.2L200.477 102.999C136.35 129.197 85.788 181.526 62.1791 246.666L137.888 264.609C152.139 220.116 185.145 183.927 227.683 165.2Z"
            fill={
              selectedZone.includes("G") || selectedAll
                ? zoneGbg >= 70
                  ? bg1
                  : zoneGbg >= 50 && zoneGbg <= 69
                  ? bg2
                  : zoneGbg >= 40 && zoneGbg <= 49
                  ? bg3
                  : zoneGbg >= 30 && zoneGbg <= 39
                  ? bg4
                  : zoneGbg >= 20 && zoneGbg <= 29
                  ? bg5
                  : zoneGbg >= 10 && zoneGbg <= 19
                  ? bg6
                  : zoneGbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="G"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M356.697 166.595C337.427 158.502 316.241 154.026 294.001 154.026C271.749 154.026 250.552 158.508 231.273 166.61L259.027 230.061C269.755 225.446 281.578 222.887 294.001 222.887C306.407 222.887 318.214 225.438 328.932 230.043L356.697 166.595Z"
            fill={
              selectedZone.includes("M") || selectedAll
                ? zoneMbg >= 70
                  ? bg1
                  : zoneMbg >= 50 && zoneMbg <= 69
                  ? bg2
                  : zoneMbg >= 40 && zoneMbg <= 49
                  ? bg3
                  : zoneMbg >= 30 && zoneMbg <= 39
                  ? bg4
                  : zoneMbg >= 20 && zoneMbg <= 29
                  ? bg5
                  : zoneMbg >= 10 && zoneMbg <= 19
                  ? bg6
                  : zoneMbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="M"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M228.773 167.694C187.01 186.081 154.592 221.586 140.546 265.242L210.548 281.832C218.466 259.427 235.177 241.166 256.543 231.183L228.773 167.694Z"
            fill={
              selectedZone.includes("L") || selectedAll
                ? zoneLbg >= 70
                  ? bg1
                  : zoneLbg >= 50 && zoneLbg <= 69
                  ? bg2
                  : zoneLbg >= 40 && zoneLbg <= 49
                  ? bg3
                  : zoneLbg >= 30 && zoneLbg <= 39
                  ? bg4
                  : zoneLbg >= 20 && zoneLbg <= 29
                  ? bg5
                  : zoneLbg >= 10 && zoneLbg <= 19
                  ? bg6
                  : zoneLbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="L"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M155.426 0H7.19297C3.22057 0 0.000305176 3.21599 0.000305176 7.18312V231.926L59.5116 246.029C83.331 180.05 134.483 127.039 199.386 100.504L155.426 0Z"
            fill={
              selectedZone.includes("B") || selectedAll
                ? zoneBbg >= 70
                  ? bg1
                  : zoneBbg >= 50 && zoneBbg <= 69
                  ? bg2
                  : zoneBbg >= 40 && zoneBbg <= 49
                  ? bg3
                  : zoneBbg >= 30 && zoneBbg <= 39
                  ? bg4
                  : zoneBbg >= 20 && zoneBbg <= 29
                  ? bg5
                  : zoneBbg >= 10 && zoneBbg <= 19
                  ? bg6
                  : zoneBbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M201.911 99.4865C230.398 88.2025 261.47 81.9996 294 81.9996C326.522 81.9996 357.587 88.2007 386.069 99.4793L429.603 0.00128174H158.395L201.911 99.4865Z"
            fill={
              selectedZone.includes("C") || selectedAll
                ? zoneCbg >= 70
                  ? bg1
                  : zoneCbg >= 50 && zoneCbg <= 69
                  ? bg2
                  : zoneCbg >= 40 && zoneCbg <= 49
                  ? bg3
                  : zoneCbg >= 30 && zoneCbg <= 39
                  ? bg4
                  : zoneCbg >= 20 && zoneCbg <= 29
                  ? bg5
                  : zoneCbg >= 10 && zoneCbg <= 19
                  ? bg6
                  : zoneCbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.08 267.216L61.2627 249.245C52.4373 274.478 47.6407 301.59 47.6407 329.813V366.056H130.215V313.957C130.215 297.71 132.616 282.019 137.08 267.216Z"
            fill={
              selectedZone.includes("F") || selectedAll
                ? zoneFbg >= 70
                  ? bg1
                  : zoneFbg >= 50 && zoneFbg <= 69
                  ? bg2
                  : zoneFbg >= 40 && zoneFbg <= 49
                  ? bg3
                  : zoneFbg >= 30 && zoneFbg <= 39
                  ? bg4
                  : zoneFbg >= 20 && zoneFbg <= 29
                  ? bg5
                  : zoneFbg >= 10 && zoneFbg <= 19
                  ? bg6
                  : zoneFbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M450.919 267.208C455.386 282.013 457.787 297.708 457.787 313.957V366.055H540.361V329.813C540.361 301.588 535.564 274.477 526.739 249.243L450.919 267.208Z"
            fill={
              selectedZone.includes("J") || selectedAll
                ? zoneJbg >= 70
                  ? bg1
                  : zoneJbg >= 50 && zoneJbg <= 69
                  ? bg2
                  : zoneJbg >= 40 && zoneJbg <= 49
                  ? bg3
                  : zoneJbg >= 30 && zoneJbg <= 39
                  ? bg4
                  : zoneJbg >= 20 && zoneJbg <= 29
                  ? bg5
                  : zoneJbg >= 10 && zoneJbg <= 19
                  ? bg6
                  : zoneJbg < 10
                  ? bg7
                  : "#7e8299"
                : "#7e8299"
            }
            id="J"
          />
          <path
            d="M18.7467 308.056H16.7779L20.3624 297.874H22.6394L26.2289 308.056H24.2602L21.5407 299.962H21.4612L18.7467 308.056ZM18.8113 304.063H24.1806V305.545H18.8113V304.063Z"
            fill="#181C32"
          />
          <path
            d="M563.253 308.056V297.874H569.875V299.42H565.097V302.184H569.532V303.73H565.097V306.509H569.915V308.056H563.253Z"
            fill="#181C32"
          />
          <path
            d="M63.404 100.056V89.8738H67.3017C68.0375 89.8738 68.649 89.9898 69.1362 90.2219C69.6268 90.4506 69.993 90.7638 70.2349 91.1615C70.4802 91.5592 70.6028 92.01 70.6028 92.5138C70.6028 92.9281 70.5233 93.2827 70.3642 93.5777C70.2051 93.8693 69.9913 94.1063 69.7229 94.2886C69.4544 94.4709 69.1545 94.6018 68.823 94.6814V94.7808C69.1843 94.8007 69.5306 94.9117 69.8621 95.1139C70.1968 95.3128 70.4703 95.5945 70.6824 95.9591C70.8945 96.3237 71.0006 96.7645 71.0006 97.2815C71.0006 97.8085 70.873 98.2825 70.6178 98.7034C70.3626 99.121 69.9781 99.4508 69.4644 99.6927C68.9506 99.9347 68.3043 100.056 67.5254 100.056H63.404ZM65.2484 98.5145H67.2321C67.9016 98.5145 68.3839 98.3869 68.6788 98.1317C68.9771 97.8731 69.1263 97.5417 69.1263 97.1373C69.1263 96.8357 69.0517 96.564 68.9026 96.322C68.7534 96.0767 68.5413 95.8845 68.2662 95.7453C67.9911 95.6028 67.663 95.5315 67.2818 95.5315H65.2484V98.5145ZM65.2484 94.2041H67.073C67.3912 94.2041 67.6779 94.1461 67.9331 94.0301C68.1883 93.9108 68.3888 93.7434 68.5347 93.528C68.6838 93.3092 68.7584 93.0507 68.7584 92.7524C68.7584 92.358 68.6192 92.0332 68.3408 91.778C68.0657 91.5228 67.6563 91.3952 67.1128 91.3952H65.2484V94.2041Z"
            fill="#181C32"
          />
          <path
            d="M520.883 100.056H517.432V89.8738H520.952C521.963 89.8738 522.831 90.0777 523.557 90.4854C524.286 90.8897 524.847 91.4714 525.238 92.2304C525.629 92.9894 525.824 93.8975 525.824 94.9548C525.824 96.0154 525.627 96.9269 525.233 97.6892C524.842 98.4515 524.277 99.0365 523.537 99.4442C522.802 99.8518 521.917 100.056 520.883 100.056ZM519.277 98.4598H520.793C521.502 98.4598 522.094 98.3305 522.568 98.072C523.042 97.8102 523.398 97.4207 523.637 96.9037C523.875 96.3833 523.995 95.7337 523.995 94.9548C523.995 94.1759 523.875 93.5296 523.637 93.0159C523.398 92.4988 523.045 92.1127 522.578 91.8575C522.114 91.599 521.537 91.4697 520.848 91.4697H519.277V98.4598Z"
            fill="#181C32"
          />
          <path
            d="M299.018 42.3092H297.158C297.105 42.0043 297.007 41.7342 296.865 41.4988C296.722 41.2602 296.545 41.058 296.333 40.8923C296.121 40.7266 295.879 40.6023 295.607 40.5194C295.339 40.4333 295.049 40.3902 294.737 40.3902C294.184 40.3902 293.693 40.5294 293.265 40.8078C292.838 41.0829 292.503 41.4872 292.261 42.0209C292.019 42.5512 291.898 43.1991 291.898 43.9648C291.898 44.7436 292.019 45.3999 292.261 45.9335C292.506 46.4638 292.841 46.8648 293.265 47.1366C293.693 47.4051 294.182 47.5393 294.732 47.5393C295.037 47.5393 295.322 47.4996 295.587 47.42C295.856 47.3372 296.096 47.2162 296.308 47.0571C296.524 46.898 296.704 46.7024 296.85 46.4704C296.999 46.2384 297.102 45.9733 297.158 45.675L299.018 45.6849C298.948 46.1688 298.797 46.6229 298.565 47.0471C298.337 47.4714 298.037 47.8459 297.665 48.1707C297.294 48.4922 296.86 48.7441 296.363 48.9264C295.866 49.1054 295.314 49.1949 294.707 49.1949C293.812 49.1949 293.014 48.9877 292.311 48.5734C291.608 48.1591 291.055 47.5609 290.65 46.7787C290.246 45.9965 290.044 45.0585 290.044 43.9648C290.044 42.8677 290.248 41.9297 290.655 41.1508C291.063 40.3686 291.618 39.7704 292.321 39.3561C293.024 38.9418 293.819 38.7346 294.707 38.7346C295.274 38.7346 295.801 38.8142 296.288 38.9733C296.775 39.1324 297.21 39.366 297.591 39.6743C297.972 39.9792 298.285 40.3537 298.53 40.7979C298.779 41.2387 298.941 41.7425 299.018 42.3092Z"
            fill="#181C32"
          />
          <path
            d="M290.296 123.056V112.874H292.14V117.184H296.858V112.874H298.708V123.056H296.858V118.73H292.14V123.056H290.296Z"
            fill="#181C32"
          />
          <path
            d="M289.161 184.874H291.418L294.441 192.252H294.56L297.583 184.874H299.84V195.056H298.07V188.061H297.975L295.162 195.026H293.839L291.025 188.046H290.931V195.056H289.161V184.874Z"
            fill="#181C32"
          />
          <path
            d="M90.403 316.056V305.874H96.9257V307.42H92.2475V310.184H96.4783V311.73H92.2475V316.056H90.403Z"
            fill="#181C32"
          />
          <path
            d="M497.098 305.874H498.927V313.033C498.924 313.689 498.785 314.254 498.51 314.728C498.235 315.199 497.85 315.562 497.356 315.817C496.866 316.069 496.294 316.195 495.641 316.195C495.045 316.195 494.508 316.089 494.03 315.877C493.556 315.661 493.18 315.343 492.902 314.922C492.623 314.501 492.484 313.978 492.484 313.351H494.319C494.322 313.626 494.382 313.863 494.498 314.062C494.617 314.261 494.781 314.413 494.99 314.519C495.199 314.626 495.439 314.679 495.711 314.679C496.006 314.679 496.256 314.617 496.461 314.495C496.667 314.369 496.823 314.183 496.929 313.938C497.038 313.693 497.094 313.391 497.098 313.033V305.874Z"
            fill="#181C32"
          />
          <path
            d="M144.041 184.125C143.959 183.857 143.844 183.617 143.698 183.404C143.556 183.189 143.384 183.005 143.181 182.853C142.982 182.7 142.754 182.586 142.495 182.509C142.237 182.43 141.955 182.39 141.65 182.39C141.103 182.39 140.616 182.528 140.188 182.803C139.761 183.078 139.424 183.482 139.179 184.016C138.937 184.546 138.816 185.193 138.816 185.955C138.816 186.724 138.937 187.375 139.179 187.909C139.421 188.442 139.758 188.848 140.188 189.127C140.619 189.402 141.12 189.539 141.69 189.539C142.207 189.539 142.654 189.44 143.032 189.241C143.413 189.042 143.707 188.76 143.912 188.396C144.118 188.028 144.22 187.597 144.22 187.103L144.638 187.168H141.874V185.726H146.005V186.949C146.005 187.821 145.82 188.575 145.448 189.211C145.077 189.848 144.567 190.338 143.917 190.683C143.267 191.024 142.522 191.195 141.68 191.195C140.742 191.195 139.918 190.984 139.209 190.563C138.503 190.139 137.951 189.538 137.553 188.759C137.159 187.977 136.962 187.049 136.962 185.975C136.962 185.153 137.078 184.419 137.31 183.772C137.545 183.126 137.873 182.577 138.294 182.127C138.715 181.673 139.209 181.328 139.776 181.093C140.343 180.854 140.959 180.735 141.625 180.735C142.189 180.735 142.714 180.818 143.201 180.983C143.688 181.146 144.121 181.378 144.499 181.679C144.88 181.981 145.193 182.339 145.438 182.753C145.684 183.167 145.844 183.625 145.921 184.125H144.041Z"
            fill="#181C32"
          />
          <path
            d="M198.04 235.056V224.874H199.884V233.509H204.369V235.056H198.04Z"
            fill="#181C32"
          />
          <path
            d="M389.683 224.874V235.056H388.043L383.245 228.12H383.161V235.056H381.316V224.874H382.967L387.759 231.814H387.849V224.874H389.683Z"
            fill="#181C32"
          />
          <path
            d="M441.928 180.874V191.056H440.084V180.874H441.928Z"
            fill="#181C32"
          />
          <path
            d="M163.267 325.056V314.874H165.112V319.552H165.236L169.208 314.874H171.46L167.523 319.443L171.495 325.056H169.278L166.24 320.691L165.112 322.023V325.056H163.267Z"
            fill="#181C32"
          />
          <path
            d="M423.182 319.965C423.182 321.062 422.976 322.001 422.565 322.784C422.158 323.563 421.601 324.159 420.895 324.573C420.192 324.988 419.395 325.195 418.503 325.195C417.612 325.195 416.813 324.988 416.107 324.573C415.404 324.156 414.848 323.558 414.437 322.779C414.029 321.996 413.825 321.059 413.825 319.965C413.825 318.868 414.029 317.93 414.437 317.151C414.848 316.369 415.404 315.77 416.107 315.356C416.813 314.942 417.612 314.735 418.503 314.735C419.395 314.735 420.192 314.942 420.895 315.356C421.601 315.77 422.158 316.369 422.565 317.151C422.976 317.93 423.182 318.868 423.182 319.965ZM421.327 319.965C421.327 319.193 421.206 318.541 420.964 318.011C420.726 317.477 420.394 317.075 419.97 316.803C419.546 316.528 419.057 316.39 418.503 316.39C417.95 316.39 417.461 316.528 417.037 316.803C416.613 317.075 416.279 317.477 416.038 318.011C415.799 318.541 415.68 319.193 415.68 319.965C415.68 320.737 415.799 321.39 416.038 321.924C416.279 322.454 416.613 322.857 417.037 323.132C417.461 323.403 417.95 323.539 418.503 323.539C419.057 323.539 419.546 323.403 419.97 323.132C420.394 322.857 420.726 322.454 420.964 321.924C421.206 321.39 421.327 320.737 421.327 319.965Z"
            fill="#181C32"
          />
        </svg>
      </div>
      <div className="color-row">
        <div className="color">
          <span>COLOR</span>
          (%)
        </div>
        <div>
          <span className="color-1"></span>
          &gt; 70
        </div>
        <div>
          <span className="color-2"></span>
          50-69
        </div>
        <div>
          <span className="color-3"></span>
          40-49
        </div>
        <div>
          <span className="color-4"></span>
          30-39
        </div>
        <div>
          <span className="color-5"></span>
          20-29
        </div>
        <div>
          <span className="color-6"></span>
          10-19
        </div>
        <div>
          <span className="color-7"></span>
          &lt; 10
        </div>

        <div span={3}></div>
      </div>
      <div className="zone-score">
        <Button
          className="red-base-btn"
          onClick={selectAllZone}
          disabled={selectedZone.length === 12 || selectedAll}
          style={{ color: color, background: lightButtonBackground }}
        >
          Select All Zones
        </Button>
        <div className="selection-div">
          {selectedZone.length > 0 && (
            <span className="selection">
              {selectedZone.length === 0 ? "All zone" : selectedZone.length}{" "}
              selected
            </span>
          )}
          <span className="clear" onClick={clearZone}>
            Clear All Zones
          </span>
        </div>
        <div className="scores">
          <span>Selected Zone Total</span>
          <span className="total">
            {!selectedAll ? selectedTestTotal : testTotal}/
            {!selectedAll ? selectedZoneTotal : zoneTotal}
            {!selectedAll > 0 ? (
              <>
                (
                {isNaN((selectedTestTotal / selectedZoneTotal) * 100)
                  ? 0
                  : ((selectedTestTotal / selectedZoneTotal) * 100).toFixed(1)}
                %){" "}
              </>
            ) : (
              <>
                (
                {isNaN((testTotal / zoneTotal) * 100)
                  ? 0
                  : ((testTotal / zoneTotal) * 100).toFixed(1)}
                %)
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
export default Chart;
