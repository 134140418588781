import React, { useContext, useState } from "react";
import RightArrow from "../../../assets/images/arrows-right.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { UserContext } from "../../../context/user-context";
import { Link, useLocation } from "react-router-dom";
import { PrivateWorkoutsContext } from "../../../context/group-context/private-workouts";
import { Row } from "antd";
import WorkoutTaskDetail from "./workout-task-detail";

const WorkoutTasks = ({ setWorkoutTasksView }) => {
  const { color } = useContext(UserContext);
  const location = useLocation();
  const { workoutDetail, workoutTaskDetail } = useContext(
    PrivateWorkoutsContext
  );
  const [showTaskDetail, setShowTaskDetail] = useState(false);
  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if (crumb !== "home" && crumb !== "groups") {
        let newCrumb = crumb.replace("-", " ");
        return (
          <div className="crumb" key={newCrumb}>
            <Link style={{ cursor: "text" }}>{decodeURI(newCrumb)}</Link>
          </div>
        );
      }
    });
  return showTaskDetail === false ? (
    <div className="workout-tasks-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          style={{ margin: "33px 0 8px", color: color, stroke: color }}
          onClick={() => {
            setWorkoutTasksView(false);
          }}
        />
        <h1>{workoutDetail?.workout?.title}</h1>
      </div>
      <div className="breadcrumbs">
        <div style={{ display: "flex" }}>
          {crumbs}
          <div className="crumb" style={{ cursor: "text" }}>
            <a style={{ cursor: "text" }}>{workoutDetail?.workout?.title}</a>
          </div>
        </div>
      </div>
      {workoutDetail?.workout?.tasks.map((item, index) => (
        <Row className="tasks-row" key={index}>
          {item.title} ({item.skill_level})
          <div className="task-type">
            {item?.scored_task === true ? "Scored Task" : "Training Task"}
          </div>
          <img
            src={RightArrow}
            onClick={() => {
              setShowTaskDetail(true);
              workoutTaskDetail(item.id);
            }}
          />
        </Row>
      ))}
    </div>
  ) : (
    <WorkoutTaskDetail setShowTaskDetail={setShowTaskDetail} />
  );
};

export default WorkoutTasks;
