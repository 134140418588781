import React, { useEffect, useState } from "react";
import {
  getData,
  formPostData,
  jsonPostData,
  patchData,
} from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import dayjs from "dayjs";
export const CombineMeasurementContext = React.createContext({});
export const CombineMeasurementContextProvider = ({ children = {} }) => {
  const [editModal, setEditModal] = useState(false);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allProfileMeasurements, setAllProfileMeasurements] = useState({});
  const [measurementDate, setMeasurementDate] = useState("");
  const [singleProfileData, setSingleProfileData] = useState([]);
  const getCombineMeasurements = () => {
    setLoading(true);
    getData(`admin/profile/${params.id}/cm`)
      .then((res) => {
        if (res && res.status === 200) {
          setAllProfileMeasurements(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSpecificProfileCm = () => {
    getData(`admin/profile/${params.id}/cm/${measurementDate}`)
      .then((res) => {
        setSingleProfileData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const submitCombineMeasurements = (values) => {
    let height =
      (values.height_feet ? values.height_feet * 12 : 0) +
      (values.height_inch ? values.height_inch : 0) +
      (values.height_dec ? "." + values.height_dec : 0);
    let weight = values.weight_lbs ? values.weight_lbs : 0;
    let wingspan =
      (values.wingspan_feet ? values.wingspan_feet * 12 : 0) +
      (values.wingspan_inch ? values.wingspan_inch : 0) +
      (values.wingspan_dec ? "." + values.wingspan_dec : 0);
    let maxVertical =
      (values.maxVertical_inch ? values.maxVertical_inch : 0) +
      (values.maxVertical_dec ? "." + values.maxVertical_dec : 0);
    let standingVertical =
      (values.standingVertical_inch ? values.standingVertical_inch : 0) +
      (values.standingVertical_dec ? "." + values.standingVertical_dec : 0);
    let court =
      (values.court_sec ? values.court_sec : 0) +
      (values.court_dec ? "." + values.court_dec : 0);
    let lane =
      (values.lane_sec ? values.lane_sec : 0) +
      (values.lane_dec ? "." + values.lane_dec : 0);
    let standingReach =
      (values.standing_reach_feet ? values.standing_reach_feet * 12 : 0) +
      (values.standing_reach_inch ? values.standing_reach_inch : 0) +
      (values.standing_reach_dec ? "." + values.standing_reach_dec : 0);
    let data = [
      {
        measurement_id: 1,
        value: parseFloat(height),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 2,
        value: parseFloat(weight),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 3,
        value: parseFloat(wingspan),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 4,
        value: parseFloat(maxVertical),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 5,
        value: parseFloat(standingVertical),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 6,
        value: parseFloat(court),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 7,
        value: parseFloat(lane),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 8,
        value: parseFloat(standingReach),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
    ];
    data = data.filter((pl) => pl.value !== 0);
            if(data?.length === 0){
              message.error({
                content:"Please add atleast 1 measurement"
              })
            }else{
            jsonPostData(`admin/profile/${params.id}/cm`, data)
              .then((res) => {
                setIsModalOpen(false)
              })
              .catch((err) => {
                if(err && err.response.status === 500){
                  message.error({
                    content:err.response.data.details
                  })
                }
              });
    }
  };
  const updateCombineMeasurements = (values) => {
    let height =
    (values.height_feet ? values.height_feet * 12 : 0) +
    (values.height_inch ? values.height_inch : 0) +
    (values.height_dec && (values.height_dec - Math. floor(values.height_dec)) !== 0 ? values.height_dec : values.height_dec ? "." + values.height_dec : 0);
  let weight = values.weight_lbs ? values.weight_lbs : 0;
  let wingspan =
    (values.wingspan_feet ? values.wingspan_feet * 12 : 0) +
    (values.wingspan_inch ? values.wingspan_inch : 0) +
    (values.wingspan_dec && (values.wingspan_dec - Math. floor(values.wingspan_dec)) !== 0 ? values.wingspan_dec : values.wingspan_dec  ? "." + values.wingspan_dec : 0);
  let maxVertical =
    (values.maxVertical_inch ? values.maxVertical_inch : 0) +
    (values.maxVertical_dec && (values.maxVertical_dec - Math. floor(values.maxVertical_dec)) !== 0 ? values.maxVertical_dec : values.maxVertical_dec   ? "." + values.maxVertical_dec : 0);
  let standingVertical =
    (values.standingVertical_inch ? values.standingVertical_inch : 0) +
    (values.standingVertical_dec && (values.standingVertical_dec - Math. floor(values.standingVertical_dec)) !== 0 ? values.standingVertical_dec : values.standingVertical_dec   ? "." + values.standingVertical_dec : 0);
  let court =
    (values.court_sec ? values.court_sec : 0) +
    (values.court_dec && (values.court_dec - Math. floor(values.court_dec)) !== 0 ? values.court_dec : values.court_dec  ? "." + values.court_dec : 0);
  let lane =
    (values.lane_sec ? values.lane_sec : 0) +
    (values.lane_dec && (values.lane_dec - Math. floor(values.lane_dec)) !== 0 ? values.lane_dec : values.lane_dec  ? "." + values.lane_dec : 0);
  let standingReach =
    (values.standing_reach_feet ? values.standing_reach_feet * 12 : 0) +
    (values.standing_reach_inch ? values.standing_reach_inch : 0) +
    (values.standing_reach_dec && (values.standing_reach_dec - Math. floor(values.standing_reach_dec)) !== 0 ? values.standing_reach_dec : values.standing_reach_dec  ? "." + values.standing_reach_dec : 0);
    let data = [
      {
        measurement_id: 1,
        value: parseFloat(height),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 2,
        value: parseFloat(weight),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 3,
        value: parseFloat(wingspan),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 4,
        value: parseFloat(maxVertical),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 5,
        value:parseFloat(standingVertical),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 6,
        value:parseFloat(court),

        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 7,
        value:parseFloat(lane),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
      {
        measurement_id: 8,
        value:parseFloat(standingReach),
        type: "1",
        cm_date: dayjs(values.date).format("YYYY-MM-DD"),
      },
    ];
    data = data.filter((pl) => pl.value !== "");
    if (data?.length === 0) {
      message.error({
        content: "Please add atleast 1 measurement",
      });
    } else {
      patchData(`admin/profile/${params.id}/cm/${measurementDate}`, data)
        .then((res) => {
          setEditModal(false);
          getCombineMeasurements();
        })
        .catch((err) => {
          console.log(err);
          if (err && err.response.status === 500) {
            message.error({
              content: "You cannot add multiple data on same date.",
            });
          }
        });
    }
  };
  useEffect(() => {
    if (!isModalOpen) {
      getCombineMeasurements();
    }
  }, [isModalOpen]);
  const defaultContext = {
    editModal,
    setEditModal,
    allProfileMeasurements,
    isModalOpen,
    setIsModalOpen,
    loading,
    submitCombineMeasurements,
    setMeasurementDate,
    getSpecificProfileCm,
    singleProfileData,
    measurementDate,
    updateCombineMeasurements,
  };
  return (
    <CombineMeasurementContext.Provider value={defaultContext}>
      {children}
    </CombineMeasurementContext.Provider>
  );
};
