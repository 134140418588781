import React, { useContext, useState } from "react";
import "./index.scss";
import Search from "../../../assets/images/search.svg";
import { Divider, Input, Skeleton, Table } from "antd";
import { UserContext } from "../../../context/user-context";
import Avatar from "react-avatar";
import {
  PrivateWorkoutsContext,
  PrivateWorkoutsContextProvider,
} from "../../../coach-context/group-context/private-workouts";
import MemberWorkouts from "./member-workots";

const PrivateWorkoutsPage = () => {
  const { selectedSport, color } = useContext(UserContext);
  const {
    privateWorkoutData,
    setPrivateWorkoutData,
    offset,
    limit,
    setOffset,
    profileWorkoutList,
    loading,
    setSearch,
    imageLoaded,
    setImageLoaded,
    memberWorkouts,
    setMemberWorkouts
  } = useContext(PrivateWorkoutsContext);
  const [pageNumber, setPageNumber] = useState(1);
  const searchPW = (e) => {
    if (e.target.value.length >= 3) {
      setPrivateWorkoutData([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setPrivateWorkoutData((privateWorkoutData) => []);
      setSearch("");
    }
  };
  const columns = [
    {
      title: "Member",
      dataIndex: "member",
      render: (text, record, index) => (
        <div style={{ display: "flex" }}>
          {record?.avatar !== null && imageLoaded === "" && (
            <Skeleton.Image active={true} className="user-skeleton" />
          )}
          {!record?.avatar ? (
            <Avatar
              name={record?.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          ) : (
            <img
              src={record?.avatar}
              style={{
                width: 40,
                height: 40,
                display: imageLoaded === "" ? "none" : "block",
                borderRadius: 6,
              }}
              onLoad={() => setImageLoaded("loaded")}
            ></img>
          )}
          <div className="name-div">
            <h3 className="user-name">{record?.name}</h3>
            <h4 className="user-handle">@{record?.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "completed",
      dataIndex: "completed",
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <span>{record.completed_workouts}</span>
          <div
            style={{ cursor: "pointer", color: color, marginLeft: 5 }}
            className="view-submissions"
          >
            ({record.new_reps} new rep)
          </div>
        </div>
      ),
    },
    {
      title: "In progress",
      dataIndex: "progress",
      render: (text, record) => <span>{record.pending_workouts} </span>,
    },
    {
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record?.new_reps !== 0 && <span className="new-sub"></span>}
        </div>
      ),
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== pageNumber) {
      setPageNumber(pagination.current);
      setOffset(pagination.current - 1 + "0");
    }
  };
  return (
    <div className="private-workouts-main">
      {memberWorkouts === false ? (
        <>
          <div style={{ display: "flex", marginBottom: 32 }}>
            <h1>Private Workouts</h1>
          </div>
          <Divider orientationMargin={0} />
          <div style={{ display: "flex" }}>
            <div className="workout-count">
              <span>{privateWorkoutData?.my_wo_members}</span> of{" "}
              <span>{privateWorkoutData?.total_members}</span> members have
              private workout
            </div>
            <div style={{ marginLeft: "auto" }}>
              <Input
                addonBefore={<img src={Search} />}
                placeholder="Search"
                className="search-input"
                onChange={searchPW}
                style={{ width: 300 }}
              />
            </div>
          </div>
          <div className="sessions-main">
            <div style={{ width: "100%" }}>
              <Table
                onChange={handleTableChange}
                rowKey={(obj) => obj.id}
                columns={columns}
                pagination={{
                  pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
                  showSizeChanger: false,
                  locale: { items_per_page: "" },
                  defaultPageSize: 10,
                  current: offset + 1,
                  pageSize: limit,
                  total: privateWorkoutData?.total_members,
                }}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setMemberWorkouts(true);
                      profileWorkoutList(record.profile_id);
                    },
                  };
                }}
                dataSource={privateWorkoutData.results}
                className="tasks-table"
                loading={loading}
              />
            </div>
          </div>
        </>
      ) : (
        <MemberWorkouts />
      )}
    </div>
  );
};

const PrivateWorkouts = () => {
  return (
    <PrivateWorkoutsContextProvider>
      <PrivateWorkoutsPage />
    </PrivateWorkoutsContextProvider>
  );
};

export default PrivateWorkouts;
