import { Button, Modal, Spin, Col, Row, Table, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import DownArrow from "../../../assets/images/arrows.svg";
import { UserContext } from "../../../context/user-context";
import Check from "../../../assets/images/check.svg";
import Cross from "../../../assets/images/cross.svg";
import { VisionShootingContext } from "../../../context/user-context/vision-shooting-context";
import Chart from "../../shared/chart";
const style = {
  padding: "5px 0",
};
const ShootingDetailModal = () => {
  const {
    shootingDetail,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    updataVisions,
    ptr2Make,
    setPtr2Make,
    ptr3Make,
    setPtr3Make,
    tableData,
    setTableData,
    setLoading,
    loading,
  } = useContext(VisionShootingContext);
  const { selectedSport, color, lightButtonBackground, darkButtonBackground } =
    useContext(UserContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const [editingKey, setEditingKey] = useState(false);
  const zone = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K" , "L" , "M","N" ,"O" , "P"];
  const cancelModal = () => {
    setShootingDetail({});
    setShootingDetailModal(false);
  };
  const zoneOptions = [];
  for (let i = 0; i < zone.length; i++) {
    zoneOptions.push({
      value: zone[i],
      label: zone[i],
    });
  }
  const [selectedZone, setSelectedZone] = useState();
  let updatedArray = tableData?.filter(
    (item) => item.primary_action !== "added"
  );
  const updateMake = (index) => {
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.make !== true) {
        if (item.ptr === 2) {
          setPtr2Make(ptr2Make + item.ptr);
        } else {
          setPtr3Make(ptr3Make + item.ptr);
        }
        return {
          ...item,
          make: true,
          score: 1,
        };
      } else {
        return item;
      }
    });
    setTableData(newArray);
  };
  const edit = (record) => {
    setEditingKey(true);
  };
  const updateMiss = (index) => {
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.make !== false) {
        if (item.ptr === 2) {
          setPtr2Make(ptr2Make - item.ptr);
        } else {
          setPtr3Make(ptr3Make - item.ptr);
        }
        return {
          ...item,
          make: false,
          score: 0,
        };
      } else {
        return item;
      }
    });
    setTableData(newArray);
  };
  const updateZone = (record, index) => {
    const newArray = updatedArray.map((item, i) => {
      if (record.id === item.id) {
        setSelectedZone(index[record.id]);
        return {
          ...item,
          zone: index,
        };
      } else {
        return item;
      }
    });
    setTableData(newArray);
  };
  const columns = [
    {
      title: "Shot count",
      dataIndex: "count",
      key: "count",
      render: (text, record, index) => {
        return (
          <div key={index}>
            <span className="span-1">{index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "zone",
      dataIndex: "zone",
      key: "zone",
      render: (text, record, index) => {
        return (
          <Select
            placeholder="Newly Added"
            optionFilterProp="children"
            onChange={(e) => updateZone(record, e)}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            defaultValue={record.zone}
            value={selectedZone}
            popupClassName={
              "custom-action-select-dropdown " +
              (selectedSport === 1
                ? "orange-theme-select"
                : "blue-theme-select")
            }
            options={zoneOptions}
          />
        );
      },
    },
    {
      title: "recorded result",
      dataIndex: "actions",
      key: "actions",
      render: (_, record, index) => {
        return editingKey ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              className={
                record.make === false ? "grey-btn-disable" : "green-btn"
              }
              onClick={() => {
                updateMake(index);
              }}
            >
              Make
            </Button>
            <Button
              className={record.make === true ? "grey-btn-disable" : "red-btn"}
              onClick={() => updateMiss(index)}
            >
              Miss
            </Button>
          </div>
        ) : (
          <span
            style={{ display: "inline" }}
            className={record.make === true ? "green-tag" : "red-tag"}
          >
            {record.make === true ? "Make" : "Miss"}
          </span>
        );
      },
    },
  ];
  useEffect(() => {
    if (Object.keys(shootingDetail).length > 0) {
      setTableData(shootingDetail?.attempts);
      setPtr2Make(shootingDetail?.total_2ptr_make);
      setPtr3Make(shootingDetail?.total_3ptr_make);
    }
  }, [shootingDetail]);
  return (
    <Modal
      className="shooting-detail-modal"
      rootClassName={
        "shooting-detail-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={shootingDetailModal}
      onCancel={() => cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      footer={false}
      destroyOnClose={true}
    >
      {Object.keys(shootingDetail).length > 0 ? (
        <div>
          <div style={{ display: "flex" }}>
            {shootingDetail?.profile?.avatar === null ? (
              <Avatar
                name={shootingDetail?.profile?.name}
                color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                maxInitials={2}
                className="profile-avatar"
              />
            ) : (
              <img
                src={shootingDetail?.profile?.avatar}
                className="profile-logo"
                style={
                  imageLoaded === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
                onLoad={() => setImageLoaded("loaded")}
              />
            )}
            <div className="name-div">
              <h3 className="user-name">{shootingDetail?.profile?.name}</h3>
              <h4 className="user-handle">
                @{shootingDetail?.profile?.username}
              </h4>
            </div>
            <div style={{ display: "flex", marginLeft: "auto" }}>
              <div className="submitted-date">
                <h3 className="user-name">Submitted</h3>
                <h4 className="user-handle">
                  {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                </h4>
              </div>
{shootingDetail.court !== "NO_THREE_POINT_LINE" &&
              <Button
                className="grey-btn"
                onClick={() => {
                  edit();
                }}
              >
                Edit
              </Button>
}
            </div>
          </div>
          {shootingDetail?.media_url !== null && (
            <ReactPlayer
              url={shootingDetail?.media_url}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
            />
          )}
          <h2>Your Shots</h2>
          <Row gutter={16} className="score-row">
            <Col className="gutter-row" span={8}>
              <div style={style} className="dotted-box">
                {" "}
                <p>
                  {shootingDetail.total_make}/{shootingDetail.total_attempts} (
                  {isNaN(
                    Math.round(shootingDetail.total_make * 100) /
                    shootingDetail.total_attempts
                  ) ? 0 : (
                    Math.round(shootingDetail.total_make * 100) /
                    shootingDetail.total_attempts
                  ).toFixed(1)}
                  %)
                </p>
                <span>Overall</span>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div style={style} className="dotted-box">
                {" "}
                <p>
                  {shootingDetail.total_2ptr_make}/
                  {shootingDetail.total_2ptr_attempts}(
                  {isNaN(
                    Math.round(shootingDetail.total_2ptr_make * 100) /
                    shootingDetail.total_2ptr_attempts
                  ) ?  0 : (
                    Math.round(shootingDetail.total_2ptr_make * 100) /
                    shootingDetail.total_2ptr_attempts
                  ).toFixed(1)}
                  %)
                </p>
                <span>2PT FG%</span>
              </div>
            </Col>
            {shootingDetail.court !== "NO_THREE_POINT_LINE" &&
            <Col className="gutter-row" span={8}>
              <div style={style} className="dotted-box">
                {" "}
                <p>
                  {shootingDetail.total_3ptr_make}/
                  {shootingDetail.total_3ptr_attempts}(
                  {isNaN(
                    Math.round(shootingDetail.total_3ptr_make * 100) /
                    shootingDetail.total_3ptr_attempts
                  ) ? 0 : (
                    Math.round(shootingDetail.total_3ptr_make * 100) /
                    shootingDetail.total_3ptr_attempts
                  ).toFixed(1)}
                  %)
                </p>
                <span>3PT FG%</span>
              </div>
            </Col>
}
          </Row>
          {shootingDetail.court !== "NO_THREE_POINT_LINE" &&
<>
          <Table
            rowKey={(obj) => obj.id}
            pagination={false}
            columns={columns}
            dataSource={updatedArray}
            className={
              "table-container " +
              (selectedSport === 1 ? "orange-theme" : "blue-theme")
            }
            scroll={{
              y: 55 * 5,
            }}
          />
          <div style={{ margin: "50px 0" }}>
            <Chart setLoading={setLoading} shotChart={shootingDetail} />
          </div>
          {editingKey === true && (
            <div style={{ float: "right" }}>
              <Button
                className="grey-btn"
                onClick={() => {
                  setEditingKey(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "10px 8px",
                  background: darkButtonBackground,
                  height: 40,
                  border: "none",
                  boxShadow: "none",
                }}
                loading={loading}
                disabled={loading}
                onClick={updataVisions}
                className="dark-base-btn"
              >
                Save
              </Button>
            </div>
          )}
          </>
}
        </div>
      ) : (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      )}
    </Modal>
  );
};
export default ShootingDetailModal;
