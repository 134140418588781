import React, { useEffect, useState } from "react";
import {
  getData,
  formPostData,
  getDataTraining,
  jsonPostDataTraining,
  patchDataTraining,
} from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
export const WorkoutContext = React.createContext({});
export const WorkoutContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const [sort, setSort] = useState(true);
  const [title, setTitle] = useState("");
  const [newTaskArray, setNewTaskArray] = useState([]);
  const [error, setError] = useState(false);
  const [current, setCurrent] = useState();
  const [privacy, setPrivacy] = useState(false);
  const [existingTaskModal, setExistingTaskModal] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workoutsData, setWorkoutsData] = useState([]);
  const [selectedWorkout, setSelectedWorkout] = useState({});
  const [workoutsSearch , setWorkoutsSearch] = useState("")
  const [limit , setLimit] = useState(10)
  const [offset , setOffset] = useState(0)
  const getAllWorkouts = () => {
    setLoading(true);
    getDataTraining(`workout?sort_desc=${sort}&search=${workoutsSearch}`)
      .then((res) => {
        setWorkoutsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createWorkout = () => {
    if (title === "") {
      setError(true);
    } else {
      setError(false);
      const list_tasks = newTaskArray
        .filter(
          (ele, ind) =>
            ind === newTaskArray.findIndex((elem) => elem.id === ele.id)
        )
        .map((e, index) => ({
          id: e.id,
          order: index + 1,
          //   lock: e.lock,
          lock: current === index || index > current && index !== 0 ? true : false,
          num_completion: isNaN(e.num_completion) ? 1 : e.num_completion,
          allow_more_reps: e.allow_more_reps,
        }));
      let workoutData = JSON.stringify({
        title: title,
        is_public: true,
        list_tasks: list_tasks,
      });
      jsonPostDataTraining(`admin/workout/library`, workoutData)
        .then((res) => {
          // setCreatedWorkout(res.data);
          navigate({
            pathname: `/home/workouts`,
            // search: `tab=workouts`,
          })
          setNewTaskArray((newTaskArray) => []);
          // setMembersView(true);
        })
        .catch((err) => {
          console.log(err);
          message.error({
            content: err.response.data.detail,
          });
        });
    }
  };
  const getSelectedworkout = () => {
    getDataTraining(`admin/workout/library/${params.id}`).then((res)=>{
      setSelectedWorkout(res.data)
    }).catch((err)=>{
      console.log(err)
    })  
  }
  const updateWorkout = (selectedWorkout) => {
    if (title === "") {
      setError(true);
    } else {
      setError(false);
      const list_tasks = newTaskArray
        .filter(
          (ele, ind) =>
            ind === newTaskArray.findIndex((elem) => elem.id === ele.id)
        )
        .map((e, index) => ({
          id: e.id,
          order: index + 1,
          // lock: current === index || index > current ? true : false,
          lock: current === index || index > current || e.lock === true && index !== 0 ? true :  false,
          num_completion: isNaN(e.num_completion) ? 1 : e.num_completion,
          allow_more_reps: e.allow_more_reps,
        }));
      let workoutData = {
        title: title,
        is_public: selectedWorkout.is_public,
        list_tasks: list_tasks,
      };
      patchDataTraining(`admin/workout/library/${params.id}`, workoutData)
        .then((res) => {
          setSelectedWorkout({});
          navigate({
            pathname: `/home/workouts`,
            // search: `tab=workouts`,
          })        })
        .catch((err) => {
          console.log(err);
          message.error({
            content: err.response.data.detail,
          });
        });
    }
  };
  useEffect(() => {
    getAllWorkouts();
  }, [sort , workoutsSearch]);
  const defaultContext = {
    getAllWorkouts,
    workoutsData,
    existingTaskModal,
    updateWorkout,
    setExistingTaskModal,
    loading,
    selectedWorkout,
    setSelectedWorkout,
    setNewTaskModal,
    setNewTaskArray,
    current,
    newTaskArray,
    newTaskModal,
    createWorkout,
    error,
    setTitle,
    setPrivacy,
    params,
    setCurrent,
    setPrivacyModal,
    setError,
    getSelectedworkout,
    title,
    sort , 
    setSort,
    setWorkoutsSearch,
    setWorkoutsData
  };
  return (
    <WorkoutContext.Provider value={defaultContext}>
      {children}
    </WorkoutContext.Provider>
  );
};
