import React , {Suspense, useContext} from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import AdminModule from "./admin-module";
// import Users from "./admin-module/users/user";
import Users from "./admin-module/Users";
// import UserAccounts from "./admin-module/user-accounts/user-accounts";
// import UserDetail from "./admin-module/Users/user-detail/user-detail";
import IndividualProfileDetail from "./admin-module/Users/individual-profile-detail/individual-profile-detail";
// import Overview from "./admin-module/overview/overview";
import GroupHome from "./admin-module/Groups/groups-home/group-home";
import CoachGroupHome from "./coach-module/Groups/groups-home/group-home"
import UserOverview from "./admin-module/Users/user-detail/user-overview";
import GroupDetail from "./admin-module/Groups/group-detail/group-detail";
import CoachGroupDetail from "./coach-module/Groups/group-detail/group-detail"
import CaochCreateGroup from "./coach-module/Groups/create-new-group/create-new-group"
import CoachProfileDetail from "./coach-module/profile-detail/individual-profile-detail/individual-profile-detail"
import ExistingWorkout from "./admin-module/Groups/existing-workout/existing-workout";
import NewWorkout from "./admin-module/Groups/new-workout/new-workout";
import Dashboard from "./admin-module/dashboard/dashboard";
import GroupLayout from "./admin-module/layout/group-layout";
import CreateGroup from "./admin-module/Groups/create-new-group/create-new-group";
import "./App.scss";
import "./shared-styling.scss"
import 'react-quill/dist/quill.snow.css';
import 'react-phone-number-input/style.css'

import { UserContext, UserContextProvider } from "./context/user-context";
import TaskHome from "./admin-module/Tasks/tasks-home/task-home";
import SignIn from "./admin-module/sign-in/signIn";
import CryptoJs from "crypto-js";
import PrivateRoutes from "./auth/Auth";
import { Spin } from "antd";
import CreateProfile from "./admin-module/Users/create-profile/create-profile";
import UserLayout from "./admin-module/layout/user-module-layout/user-module-layout";
import UserDetail from "./admin-module/Users/user-detail/user-detail";
import CreateUser from "./admin-module/Users/create-user/create-user";
import UserAccountTable from "./admin-module/Users/user-account-table/user-account-table";
import ProfileLayout from "./admin-module/layout/user-module-layout/profile-layout";
import Settings from "./admin-module/Settings"
// import CoachSettings from "./coach-module/Settings"
import CoachSettings from "./coach-module/Settings/create-profile/create-profile"
import ChallengeLayout from "./admin-module/layout/challenge-layout";
import Challenges from "./admin-module/Challenges";
import ChallengeDetail from "./admin-module/Challenges/challenge-detail/challenge-detail";
import CreateFeatureWorkout from "./admin-module/Challenges/create-new-fw/create-new-fw";
import SubmissionLayout from "./admin-module/layout/submission-layout";
import SubmissionsMain from "./admin-module/Submissions";
import SubmissionDetail from "./admin-module/Submissions/submission-detail/submission-detail";
import FWSubmissionDetail from "./admin-module/Submissions/FW-submission-detail/FW-submission-detail"
import HomeRotator from "./admin-module/Home-Rotator";
import HomeRotatorLayout from "./admin-module/layout/home-rotator-layout";
import CreateNewRotator from "./admin-module/Home-Rotator/create-new-rotator/create-new-rotator";
import EditRotator from "./admin-module/Home-Rotator/edit-rotator/edit-rotator";
import RotatorDetail from "./admin-module/Home-Rotator/rotator-detail/rotator-detail";
import CreateNewWorkout from "./admin-module/Challenges/new-workout/new-workout";
import EditWorkout from "./admin-module/Challenges/edit-workout/edit-workout";
import WorkoutDetail from "./admin-module/Challenges/workout-detail/workout-detail";
// import WorkoutDetail from "./admin-module/Challenges/workout-detail/workout-detail";
import EditUser from "./admin-module/Users/edit-user/edit-user";
import LogIn from "./coach-module/login/login";
import Verification from "./coach-module/verification/verification"
import NoSubscription from "./coach-module/no-subscription/no-subscription"
// import PurchasesLayout from "./admin-module/layout/purchases-layout";
// import Purchases from "./admin-module/Purchases/index";
import CoachPrivateRoutes from "./auth/CoachAuth";
import MainScreen from "./Main-screen/main-screen"
import ChatBlank from "./coach-module/chat-ai/blank-chat";
import TaskDetail from "./admin-module/Submissions/FW-submission-detail/single-task-deail";
import FeatureAssignedWorkout from "./admin-module/Groups/assigned-feature-workout/assigned-feature-workout"
import FeatureAssignedWorkoutCoach from "./coach-module/Groups/assigned-feature-workout/assigned-feature-workout"
import EditFW from "./admin-module/Challenges/edit-fw/edit-fw";
import ResetPassword from "./admin-module/sign-in/reset-password";
import OtpVerification from "./admin-module/sign-in/otp-verification";
import ChangePassword from "./admin-module/sign-in/new-password";
function App() {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const {selectedSport} = useContext(UserContext)
  let tokenData = "";
  let decryptStr = "";
  if (localStorage.getItem("u_info") !== null) {
    decryptStr = CryptoJs.AES.decrypt(
      localStorage.getItem("u_info"),
      "sec"
    ).toString(CryptoJs.enc.Utf8);
  }
  let tokenDataCoach = "";
  let decryptStrCoach = "";
  if (localStorage.getItem("coach_info") !== null) {
    decryptStrCoach = CryptoJs.AES.decrypt(
      localStorage.getItem("coach_info"),
      "sec"
    ).toString(CryptoJs.enc.Utf8);
  }
  if (decryptStr !== "") {
    tokenData = JSON.parse(decryptStr);
  }
  if (decryptStrCoach !== "") {
    tokenDataCoach = JSON.parse(decryptStrCoach);
  }
  const accessToken = tokenData.access_token;
  let auth = { token: accessToken };
  const accessTokenCoach = tokenDataCoach.access_token;
  let authCoach = { token : accessTokenCoach}
  return (
    <div className="App">
      <UserContextProvider>
        <BrowserRouter>
          <Suspense
            fallback={
              <Spin
                className={
                  "loader " +
                  (selectedSport === 1
                    ? "orange-theme-loader"
                    : "blue-theme-loader")
                }
                size="large"
              />
            }
          >
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="otp-verification" element={<OtpVerification />} />
              <Route path="change-password" element={<ChangePassword />} />

              {/* <Route path="/" element={<Navigate to="/home/users" />} /> */}
              <Route element={<PrivateRoutes />} path="home">
                {/* <Route index element={<Dashboard />}></Route> */}
                {/* <Route path="users" element={<Users />}></Route> */}
                <Route path="users" element={<UserLayout />}>
                  <Route index element={<Users />} />
                  <Route path="user-accounts" element={<UserDetail />} />
                  <Route path="profiles" element={<IndividualProfileDetail />}>
                    {/* <Route index path=":id" element={<IndividualProfileDetail/>} /> */}
                  </Route>
                  <Route path="create-profile" element={<CreateProfile />} />
                  <Route path="create-user" element={<CreateUser />} />
                  <Route path="edit-user" element={<EditUser />} />
                </Route>
                <Route path="groups" element={<GroupLayout />}>
                  <Route index element={<GroupHome />} />
                  <Route path="detail" element={<GroupDetail />} />
                  <Route path="create-new-group" element={<CreateGroup />} />
                  <Route
                    path="assigned-workout"
                    element={<FeatureAssignedWorkout />}
                  />
                </Route>
                <Route path="workouts" element={<ChallengeLayout />}>
                  <Route index element={<Challenges />} />
                  <Route path=":id" element={<ChallengeDetail />} />
                  <Route
                    path="create-new-featured-workout"
                    element={<CreateFeatureWorkout />}
                  />
                  <Route path="edit-featured-workout" element={<EditFW />} />
                  <Route
                    path="create-new-workout"
                    element={<CreateNewWorkout />}
                  />
                  <Route path="edit-workout" element={<EditWorkout />} />
                  <Route path="workout-detail" element={<WorkoutDetail />} />
                </Route>
                <Route path="submissions" element={<SubmissionLayout />}>
                  <Route index element={<SubmissionsMain />} />
                  <Route path="bsa-detail/:id" element={<SubmissionDetail />} />
                  <Route path=":id?/:tabs?" element={<FWSubmissionDetail />} />
                </Route>
                <Route path="home-rotator" element={<HomeRotatorLayout />}>
                  <Route index element={<HomeRotator />} />
                  <Route path="detail" element={<RotatorDetail />} />
                  <Route path="create-new" element={<CreateNewRotator />} />
                  <Route path=":id" element={<EditRotator />} />
                </Route>
                {/* <Route path="purchases" element={<PurchasesLayout/>}>
                <Route index element={<Purchases/>} />
              </Route> */}
                <Route path="tasks" element={<TaskHome />}>
                  {" "}
                </Route>

                {/* <Route path="user-accounts" element={<IndividualProfileDetail />}></Route> */}
                {/* <Route
                path="user-accounts/overview"
                element={<Overview />}
              ></Route> */}
                {/* <Route path="/home/user-detail" element={<UserDetail />}></Route> */}
                <Route path="new-workout" element={<ExistingWorkout />}></Route>
                <Route path="existing-workout" element={<NewWorkout />}></Route>
                <Route path="settings" element={<Settings />} />
              </Route>
              <Route element={<CoachPrivateRoutes />} path="coach/">
                <Route path="groups" element={<GroupLayout />}>
                  <Route index element={<CoachGroupHome />} />
                  <Route path="detail" element={<CoachGroupDetail />} />
                  <Route
                    path="create-new-group"
                    element={<CaochCreateGroup />}
                  />
                  <Route
                    path="profile-detail"
                    element={<CoachProfileDetail />}
                  />
                  <Route
                    path="assigned-workout"
                    element={<FeatureAssignedWorkoutCoach />}
                  />
                </Route>
                <Route path="settings" element={<CoachSettings />} />
              </Route>
              <Route path="chatbot" element={<ChatBlank />} />

              {auth.token && !window.location.href.includes("coach") ? (
                <Route
                  path="*"
                  element={<Navigate to="/home/users" replace />}
                />
              ) : (
                !window.location.href.includes("coach") && (
                  <Route element={<Navigate to="/" />} path="*" />
                )
              )}
              {authCoach.token && window.location.href.includes("coach") ? (
                <Route
                  path="*"
                  element={<Navigate to="/coach/groups" replace />}
                />
              ) : (
                window.location.href.includes("coach") && (
                  <Route element={<Navigate to="/coach/login" />} path="*" />
                )
              )}
              {/* <Route
                         element={<PrivateRoutes />}
                         path="coach"> */}

              <Route path="/coach/login" element={<LogIn />} />
              <Route path="/coach/verification" element={<Verification />} />
              <Route
                path="/coach/no-subscription"
                element={<NoSubscription />}
              />
              {/* </Route> */}
            </Routes>
          </Suspense>
        </BrowserRouter>
      </UserContextProvider>
    </div>
  );
}

export default App;
