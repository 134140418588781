import React, { useContext, useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { deleteItem, getDataCoach, getDataTest, jsonPostDataCoach, patchData } from "../../util/coach-api-calls";
import { useNavigate } from "react-router-dom";
export const GroupDetailContext = React.createContext({});
export const GroupDetailContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [generalDetail, setGeneralDetail] = useState([]);
  const [type, setType] = useState();
  const [level, setLevel] = useState();
  const [sportsList, setSportsList] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [bsaCredit, setBsaCredit] = useState();
  const [seatLimit, setSeatLimit] = useState(false);
  const [memberLimit, setMemberLimit] = useState();
  const [workoutLeaderBoard, setWorkoutLeaderBoard] = useState(true);
  const [combineLeaderBoard, setCombineLeaderBoard] = useState(false);
  const [bsaLeaderBoard, setBsaLeaderBoard] = useState(false);
  const [newSubscription, setNewSubscription] = useState(false);
  const [editSubscription , setEditSubscription] = useState(false)
  const [subscriptionHistory, setSubscriptionHistory] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("none");
  const [workoutDetail, setWorkoutDetail] = useState(false);
  const [existingWorkout, setExistingWorkout] = useState(false);
  const [imageLoaded, setImageLoaded] = useState("");
  const [bsaValue, setBsaValue] = useState();
  const [bsaValueOneTime, setBsaValueOneTime] = useState()
  const [bsaValueEachMonth, setBsaValueEachMonth] = useState()
  const [tags, setTags] = useState();
  const [sport, setSport] = useState("");
  const [imgSrc, setImgSrc] = useState();
  const [showGeneralDetails, setShowGeneralDetail] = useState(true);
  const [newWorkout, setNewWorkout] = useState(false);
  const [subscriptionRecord , setSubscriptionRecord] = useState([])
  const [sort , setSort] = useState(false)
  const [startDate , setStartDate] =useState("")
  const [endDate , setEndDate] = useState("")
  const [curriculum , setCurriculum] = useState(false)
  const [access,setAccess] = useState(false)
  const [selectedWorkout , setSelectedWorkout] = useState({})
  const [ viewSubmissions , setViewSubmissions] = useState(false)
  const [ imageError,setImageError] = useState(false)
  const [cgListGroup , setCgListGroup] = useState([])
  const [cgList , setCgList] = useState([])
  const [groups, setGroups] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedGroup , setSelectedGroup] = useState(params.id)
  const [editWorkout,setEditWorkout] = useState(false)
  const [editAssignWorkout,setEditAssignWorkout] = useState(false)
  const getGroupsList = () => {
    getDataCoach(`coach/group/list?page=${pageNumber}&page_size=100`)
      .then((response) => {
        setGroups(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const postEditGroup = (values) => {
    if(type === 2 && imgSrc === undefined){
      setImageError(true)
    }else{
      setImageError(false)
    let start_date = new Date(Date.parse(values.start_date)).toLocaleString(
      "sv-SE"
    );
    let expiry_Date = new Date(Date.parse(values.expiry_Date)).toLocaleString(
      "sv-SE"
    );
    let formData = new FormData();
    if (imageUrl) {
      formData.append("avatar", imageUrl);
    }
    formData.append("name", values.name);
    formData.append(
      "display_announcements_section",
      values.display_announcements_section
    );
    formData.append("display_workout_leaderboard ", workoutLeaderBoard);
    formData.append("display_bsa_leaderboard ", bsaLeaderBoard);
    if (start_date !== "Invalid Date") {
      formData.append("start_date", start_date);
    }
    if (expiry_Date !== "Invalid Date") {
      formData.append("expiry_Date", expiry_Date ? expiry_Date : false);
    }
    formData.append("is_default", false  );

    // type === 2 ? true : false
    formData.append("cg_follow_ids" ,cgListGroup.toString() )
    patchData(`group/${params.id}`, formData)
      .then((res) => {
        if (res && res.status === 200) {
          message.success({
            content: "Group updated successfully.",
            duration: 1,
          });
          setTimeout(() => {
            navigate("/coach/groups");
          }, 1200);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  const listAllCG = () =>{
    getDataCoach(`curriculum/group/${params.id}`).then((res)=>{
      setCgList(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getSportList = () => {
    getDataCoach(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroupDetail = (loading) => {
    if(loading !== false){
    setLoading(true);
  }
    getDataCoach(`group/${selectedGroup}`)
      .then((res) => {
        setGeneralDetail(res.data);
        if(res.data.is_curriculum_group === false){
          listAllCG();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSubscriptionHistory = () =>{
    getDataCoach(`admin/group/subscription?group_id=${params.id}&sort_asc=${sort}&start_date=${startDate}&end_date=${endDate}`)
    .then((res)=>{
      setSubscriptionRecord(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const addNewSubscription = (formData , setNewSubscription) => {
    jsonPostDataCoach(`admin/group/subscription`,formData)
    .then((res)=>{
      message.success({
        content: "Subscription added successfully.",
        duration: 1,
      });
      setNewSubscription(false)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const updateSubscription = (formData , id , setEditSubscription) => {
    patchData(`admin/group/subscription/${id}`,formData)
    .then((res)=>{
      message.success({
        content: "Subscription updated successfully.",
        duration: 1,
      });
      getGroupDetail();
      setEditSubscription(false)
    }).catch((err)=>{
      console.log(err)
    })
  }



   const deleteGroup = () =>{
   deleteItem(`admin/group/${params.id}`).then((res)=>{
    navigate("/home/groups");
   }).catch((error)=>{
    console.log(error)
   })
   }

  useEffect(() => {
    getSportList();
    if (params) {
      getGroupsList();
      getGroupDetail();
    }
  }, [selectedGroup]);
  useEffect(()=>{
    getSubscriptionHistory();

  },[startDate,endDate,sort])
  const defaultContext = {
    generalDetail,
    type,
    setType,
    level,
    setLevel,
    sportsList,
    setGeneralDetail,
    setSeatLimit,
    seatLimit,
    setMemberLimit,
    memberLimit,
    imgSrc,
    bsaCredit,
    showGeneralDetails,
    setShowGeneralDetail,
    newWorkout,
    setNewWorkout,
    setImgSrc,
    imageLoaded,
    setImageLoaded,
    setImageUrl,
    workoutLeaderBoard,
    bsaLeaderBoard,
    combineLeaderBoard,
    setSport,
    sport,
    workoutDetail,
    setWorkoutDetail,
    existingWorkout,
    setExistingWorkout,
    setBsaCredit,
    subscriptionStatus,
    setSubscriptionStatus,
    subscriptionHistory,
    setSubscriptionHistory,
    postEditGroup,
    bsaValue,
    setBsaValue,
    newSubscription,
    setNewSubscription,
    setWorkoutLeaderBoard,
    setBsaLeaderBoard,
    setCombineLeaderBoard,
    tags,
    setTags,
    loading,
    setLoading,
    deleteGroup,
    addNewSubscription,
    subscriptionRecord,
    editSubscription,
    setEditSubscription,
    updateSubscription,
    access,
    setAccess,
    setSort,
    setStartDate,setEndDate,
    bsaValueOneTime,setBsaValueOneTime,
    bsaValueEachMonth,setBsaValueEachMonth,setCurriculum,curriculum,
    selectedWorkout, setSelectedWorkout,
    viewSubmissions,
    setViewSubmissions,
    imageError,
    setImageError,getGroupDetail,
    cgList,
    cgListGroup ,        
    setCgListGroup,
    groups,setSelectedGroup,params,selectedGroup,
    editWorkout,
    setEditWorkout,
    editAssignWorkout,
    setEditAssignWorkout
  };
  return (
    <GroupDetailContext.Provider value={defaultContext}>
      {children}
    </GroupDetailContext.Provider>
  );
};
