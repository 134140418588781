import React, { useEffect, useState } from "react";
import { getDataTest } from "../../util/coach-api-calls";

export const ShotChartContext = React.createContext({});
export const ShotChartContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [shotChart , setShotChart] = useState({})
  const [loadig , setLoading] = useState(false)
  const getGroupShotChart = () => {
    getDataTest(`vision/group/${params.id}/shot/chart`).
    then((res)=>{
      setShotChart(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getGroupShotChart();
  },[])
  const defaultContext = {
    shotChart,
    setLoading
  };
  return (
    <ShotChartContext.Provider value={defaultContext}>
      {children}
    </ShotChartContext.Provider>
  );
};
