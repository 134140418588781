import { Button, Modal, Spin, Col, Row, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import { UserContext } from "../../../context/user-context";
import { VisionShootingContext } from "../../../context/group-context/vision-shooting-context";
import Chart from "../../shared/chart";
const style = {
  padding: "5px 0",
};
const ShootingDetailModal = () => {
  const {
    shootingDetail,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    setLoading,
    activeTab,
    deleteTeamSession
  } = useContext(VisionShootingContext);
  const { selectedSport } = useContext(UserContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const cancelModal = () => {
    setShootingDetail({});
    setShootingDetailModal(false);
  };
  return (
    <Modal
      className="shooting-detail-modal"
      rootClassName={
        "shooting-detail-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={shootingDetailModal}
      // onOk={handleOk}
      onCancel={() => cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      footer={false}
      destroyOnClose={true}
    >
      {Object.keys(shootingDetail).length > 0 ? (
        <div>
          {activeTab === "teams" ? (
            <>
              <div style={{ display: "flex" }}>
                <h1>Vision Team Shooting Results</h1>
                <div style={{ marginLeft: "auto" }} className="submitted-date">
                  <h3 className="user-name">Submitted</h3>
                  <h4 className="user-handle">
                    {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                  </h4>
                </div>
              </div>
              <div style={{ display: "flex"  , marginBottom:20}}>
                <div className="title">Session Title</div>
                <Input defaultValue={shootingDetail?.title} disabled />
              </div>
            </>
          ) : (
            <div style={{ display: "flex" }}>
              {shootingDetail?.profile?.avatar === null ? (
                <Avatar
                  name={shootingDetail?.profile?.name}
                  color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                  maxInitials={2}
                  className="profile-avatar"
                />
              ) : (
                <img
                  src={shootingDetail?.profile?.avatar}
                  className="profile-logo"
                  style={
                    imageLoaded === ""
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  onLoad={() => setImageLoaded("loaded")}
                />
              )}
              <div className="name-div">
                <h3 className="user-name">{shootingDetail?.profile?.name}</h3>
                <h4 className="user-handle">
                  @{shootingDetail?.profile?.username}
                </h4>
              </div>
              <div style={{ marginLeft: "auto" }} className="submitted-date">
                <h3 className="user-name">Submitted</h3>
                <h4 className="user-handle">
                  {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                </h4>
              </div>
            </div>
          )}
          {shootingDetail?.media_url !== null && (
            <ReactPlayer
              url={shootingDetail?.media_url}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
            />
          )}
          <h2>Your Shots</h2>
          <Row gutter={16} className="score-row">
            <Col className="gutter-row" span={8}>
              <div style={style} className="dotted-box">
                {" "}
                <p>
                  {shootingDetail.total_make}/{shootingDetail.total_attempts} (
                  {isNaN(
                    Math.round(shootingDetail.total_make * 100) /
                      shootingDetail.total_attempts
                  )
                    ? 0
                    : (
                        Math.round(shootingDetail.total_make * 100) /
                        shootingDetail.total_attempts
                      ).toFixed(1)}
                  %)
                </p>
                <span>Overall</span>
              </div>
            </Col>
            {activeTab !== "teams" && (
              <>
                <Col className="gutter-row" span={8}>
                  <div style={style} className="dotted-box">
                    {" "}
                    <p>
                      {shootingDetail.total_2ptr_make}/
                      {shootingDetail.total_2ptr_attempts}(
                      {isNaN(
                        Math.round(shootingDetail.total_2ptr_make * 100) /
                          shootingDetail.total_2ptr_attempts
                      )
                        ? 0
                        : (
                            Math.round(shootingDetail.total_2ptr_make * 100) /
                            shootingDetail.total_2ptr_attempts
                          ).toFixed(1)}
                      %)
                    </p>
                    <span>2PT FG%</span>
                  </div>
                </Col>
                {shootingDetail.court !== "NO_THREE_POINT_LINE" &&
                <Col className="gutter-row" span={8}>
                  <div style={style} className="dotted-box">
                    {" "}
                    <p>
                      {shootingDetail.total_3ptr_make}/
                      {shootingDetail.total_3ptr_attempts}(
                      {isNaN(
                        Math.round(shootingDetail.total_3ptr_make * 100) /
                          shootingDetail.total_3ptr_attempts
                      )
                        ? 0
                        : (
                            Math.round(shootingDetail.total_3ptr_make * 100) /
                            shootingDetail.total_3ptr_attempts
                          ).toFixed(1)}
                      %)
                    </p>
                    <span>3PT FG%</span>
                  </div>
                </Col>
}
              </>
            )}
          </Row>
          {activeTab === "individual" && shootingDetail.court !== "NO_THREE_POINT_LINE" &&
 (
            <Chart setLoading={setLoading} shotChart={shootingDetail} />
          )}
        </div>
      ) : (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      )}
      {activeTab === "teams" && shootingDetail.court !== "NO_THREE_POINT_LINE" &&
      <Button className="grey-btn" onClick={()=>deleteTeamSession(shootingDetail?.id)}>Delete session</Button>
      }
    </Modal>
  );
};
export default ShootingDetailModal;
