
import React, { useEffect, useState } from "react";
import { deleteItemTest, getDataTest } from "../../util/coach-api-calls";
import { message } from "antd";
export const VisionShootingContext = React.createContext({});
export const VisionShootingContextProvider = ({ children = {} }) => {
  const [visionLeaderboards , setVisionLeaderboards] = useState([])
  const [leaderboardProfile , setLeaderboardProfile] = useState({})
  const [sessionsData , setSessionsData] = useState([])
  const [teamSessionsData , setTeamSessionsData] = useState([])
  const [selectedShooting , setSelectedShooting ] = useState("")
  const [shootingDetailModal , setShootingDetailModal] = useState(false)
  const [shootingDetail , setShootingDetail] = useState({})
  const [shootingDetailView , setShootingDetailView] = useState(false)
  const [titlesList , setTitlesList] = useState([])
  const [selectedTeamSession,setSelectedTeamSession] = useState("all")
  const [totalSessions , setTotalSessions] = useState(0)
  const [activeTab , setActiveTab] = useState("individual")
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreTeam, setHasMoreTeam] = useState(true);
  const [limit , setLimit] = useState(10)
  const [offset , setOffset] = useState(0) 
  const [teamOffset , setTeamOffset] = useState(0)
  const [teamLimit , setTeamLimit] = useState(10)
  const [sort , setSort] = useState("desc")
  const [teamSort,setTeamSort] = useState("desc")
  const [search , setSearch] = useState("")
  const [searchTitle , setSearchTitle] = useState("")
  const [loading , setLoading] = useState(false)
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const getVisionLeaderBoard = () => {
    setLoading(true)
    getDataTest(`vision/group/${params.id}/leaderboard?filters=total_shots&sort=${sort}&search=${search}`)
    .then((res)=>{
      setVisionLeaderboards(res.data)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }
  const getProfileSessions = () => {
    getDataTest(`vision/profile/${leaderboardProfile?.profile_id}/session/list?offset=${offset}&limit=${limit}`)
    .then((res)=>{
      setSessionsData([...sessionsData, ...res.data.sessions]);
      if(offset === 0){
        setOffset(offset + limit)
    }else{
        setHasMore(true);
        setOffset(offset + limit)
    }
    if(res.data.sessions.length === 0){
      setHasMore(false)
    }
    }).catch((err)=>{
      console.log(err)
    }) 
   }
   const getTeamSessions = () => {
    if(teamOffset === 0){
      setLoading(true)
    }
    getDataTest(`team/vision/${params.id}/session/list?title=${selectedTeamSession === "all" ? "" : selectedTeamSession}&offset=${teamOffset}&limit=${teamLimit}&sort=${sort}`)
    .then((res)=>{
      setTeamSessionsData([...teamSessionsData, ...res.data.sessions]);
      if(teamOffset === 0){
        setTeamOffset(teamOffset + teamLimit)
    }else{
        setHasMoreTeam(true);
        setTeamOffset(teamOffset + teamLimit)
    }
    if(res.data.sessions.length === 0){
      setHasMoreTeam(false)
    }
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
   }
  const getShootingDetail = (id) => {
    let url = ""
    if(activeTab === "teams" || params.tab === "team_shooting_sessions"){
      url = `team/vision/session/${id ? id : params.session_id}`
    }else{
      url = `vision/profile/${leaderboardProfile?.profile_id}/session/${id}`
    }
    getDataTest(url)
    .then((res)=>{
      setShootingDetail(res.data)
    }).catch((err)=>{
      console.log(err)
    })  
  }
  useEffect(()=>{
    if(Object.keys(leaderboardProfile).length > 0){
    // getProfileSessions()
    }
  },[leaderboardProfile])
  const deleteTeamSession = (id) => {
    deleteItemTest(`team/vision/session/${id}`).
    then((res)=>{
      message.success({
        content:res.data.details
      })
      setTeamOffset(0)
      setTeamSessionsData([])
      setShootingDetailModal(false)
      getTeamSessions();
    }).catch((err)=>{
      console.log(err)
    })
  }
  const listAllTitles = () =>{
    getDataTest(`team/vision/${params.id}/title/list?title=${searchTitle}`).
    then((res)=>{
      setTitlesList(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const defaultContext = {
    visionLeaderboards,
    setLeaderboardProfile,
    sessionsData,
    setSelectedShooting,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    getShootingDetail,
    shootingDetail,
    selectedShooting,
    leaderboardProfile,
    loading,
    setLoading,
    shootingDetailView,
    setShootingDetailView,
    getVisionLeaderBoard,
    setVisionLeaderboards,
    sort,
    setSearch,
    setSort,
    search,
    params,
    activeTab,
    setActiveTab,
    listAllTitles,
    getTeamSessions,
    teamSessionsData,
    titlesList,
    selectedTeamSession,
    setSelectedTeamSession,
    getProfileSessions,
    hasMore,
    setHasMore,
    totalSessions,
    setTotalSessions,
    hasMoreTeam,
    setTeamOffset,
    setTeamSessionsData,
    searchTitle , 
    setSearchTitle,
    deleteTeamSession,
    setSessionsData,
    setOffset,
    teamSort,
    setTeamSort
  };
  return (
    <VisionShootingContext.Provider value={defaultContext}>
      {children}
    </VisionShootingContext.Provider>
  );
};
